export const PainterToolType = {
    Pen: 'Pen',
    Highlighter: 'Highlighter',
    Shape: 'Shape',
    Line: 'Line'
};

export const PainterType = {
    Arrow: 'arrow',
    RightTriangle: 'right_triangle',
    Line: 'line',
    Pen: 'pen',
    DottedLine: 'dotted_line',
    Circle: 'circle',
    SolidCircle: 'solid_circle',
    Rectangle: 'rectangle',
    IsoscelesTriangle: 'isosceles_triangle',
    Text: 'text',
    StickyNote: 'stickyNote',
    Link: 'link',
    Star: 'star'
};

export const PainterBrushType = {
    Pencil: 'Pencil',
    Circle: 'Circle',
    Spray: 'Spray',
    Pattern: 'Pattern',
};


export const LineType = {
    Line: 'line',
    Dash: 'dash',
}