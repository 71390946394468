import React from 'react';
import classnames from 'classnames';
import styles from './index.module.scss';

const SwitchButton = ({ 
  value = false,
  onChange = () => {}
}) => {

  const changeHandler = e => {
    onChange(e.target.checked);
  }

  return (
      <label
        className={classnames(styles.switchButton,{
          [styles.active]: value
        })}
      >
        <input
          className={styles.checkbox}
          type="checkbox"
          value={value}
          onChange={changeHandler}
        />
      </label>
  );
};

export default SwitchButton;
