import { useCallback } from 'react';
import { ReaderToolType } from 'constants/ReaderTools';
import { BaseReaderStrategy } from './BaseReaderStrategy';

const Whitelist = [
    ReaderToolType.RightSideBar,
];

export const useOneTutorSpectatorsStrategy = () => {
    const getReaderToolComponents = useCallback((readerToolComponents) => {
        return BaseReaderStrategy.getAcceptedReaderToolComponents(readerToolComponents, Whitelist);
    }, []);

    const isReaderSwipeable = useCallback(() => {
        return false;
    }, []);

    const isReaderSwitchPageAvailable = useCallback(() => {
        return false;
    }, []);

    const syncAnnotation = useCallback((id) => {
        // Do nothing
    }, []);

    const isInteractiveObjectTriggerable = useCallback(({ contentType }) => {
        return true;
    }, []);

    return {
        name: 'OneTutorSpectatorsStrategy',
        getReaderToolComponents,
        isReaderSwipeable,
        isReaderSwitchPageAvailable,
        syncAnnotation,
        isInteractiveObjectTriggerable
    };
};
