export const DefaultColors = [
    '#E71F1A',
    '#EC078D',
    '#FAEE00',
    '#F39800',
    '#924F9B',
    '#4A459A',
    '#1A499D',
    '#62B0E3',
    '#6BB82E',
    '#1C923B',
    '#B28247',
    '#00A29A',
    '#231916',
    '#595857',
    '#B5B5B6',
    '#FFFFFF',
];


export const DefaultPanelColors = [
    '#F94144',
    '#F9C74F',
    '#43AA8B',
    '#577590',
    '#000000',
];

export const DefaultBorderColors = [
    '#E71F1A',
    '#FAEE00',
    '#00761A',
    '#0000FF',
    '#000000',
];

export const DefaultFillColors = [
    '#FAEE00',
    '#D4067F',
    '#00761A',
    '#0000FF',
    '#F39800',
];

export const DefaultPanelBackgroundColors = [
    '#ffffff',
    '#FAEE00',
    '#EC078D',
    '#00761a',
    '#0000ff',
    '#F39800',
];