export const ExpressionTypes = {
    Good: { path: "assets/animations/good.json", soundSrc: "assets/animations/wav/cheers.wav" },
    Fireworks: { path: "assets/animations/fireworks.json", soundSrc: "assets/animations/mp3/fireworks.mp3" },
    Like: { path: "assets/animations/like.json", soundSrc: "assets/animations/mp3/heart.mp3" },
    Trophy: { path: "assets/animations/trophy.json", soundSrc: "assets/animations/mp3/trophy.mp3" }
}

export const EmojiTypes = {
    Good:"good",
    Fireworks:"fireworks",
    Like:"like",
    Trophy:"trophy",
}
