import React, { useCallback } from 'react';
import Icon from 'components/Icon';
import ControlButton from 'components/common/Player/ControlButton';
import useSetState from 'customHooks/setState';
import { SubtitlesPositionType, SubtitlesFontSizeType } from 'constants/videoSettingType';
import classnames from 'classnames';
import styles from './index.module.scss';


const PlaybackRateList = [
  { label: '稍慢', value: 0.75 },
  { label: '正常', value: 1 },
  { label: '稍快', value: 1.25 }
];

const subtitlesFontSize = [
  { label: '大', value: SubtitlesFontSizeType.LARGE },
  { label: '中', value: SubtitlesFontSizeType.MEDIUM },
  { label: '小', value: SubtitlesFontSizeType.SMALL }
];

const subtitlesPosition = [
  { label: '上', value: SubtitlesPositionType.UP },
  { label: '下', value: SubtitlesPositionType.DOWN }
];

const Panel = ({
  isShow = false,
  playbackRateValue = 1,
  subtitlesFontSizeValue = SubtitlesFontSizeType.MEDIUM,
  onPlaybackRateChange = () => { },
  onSubtitlesFontSizeChange = () => { },
  isShowSubtitleSetting,
  subtitlesPositionValue = subtitlesPosition.DOWN,
  onSubtitlesPositionChange = () => { }
}) => (
    <div className={classnames(styles.panel, {
      [styles.active]: isShow
    })}>
      <div className={styles.list}>
        <div className={styles.title}>播放速度</div>
        {
          PlaybackRateList.map(item => (
            <div
              key={item.value}
              className={classnames(styles.listItem, {
                [styles.active]: item.value === playbackRateValue
              }
              )}
              onClick={() => onPlaybackRateChange(item.value)}
            >
              <span className={styles.label}>{item.label}</span>
            </div>
          ))
        }
      </div>
      {
        isShowSubtitleSetting && (
          <>
            <div className={styles.list}>
              <div className={styles.title}>字級大小</div>
              {
                subtitlesFontSize.map(item => (
                  <div
                    key={item.value}
                    className={classnames(styles.listItem, {
                      [styles.active]: item.value === subtitlesFontSizeValue
                    }
                    )}
                    onClick={() => onSubtitlesFontSizeChange(item.value)}
                  >
                    <span className={styles.label}>{item.label}</span>
                  </div>
                ))
              }
            </div>
          <div className={styles.list}>
            <div className={styles.title}>字幕位置</div>
            {
              subtitlesPosition.map(item => (
                <div
                  key={item.value}
                  className={classnames(styles.listItem, {
                    [styles.active]: item.value === subtitlesPositionValue
                  }
                  )}
                  onClick={() => onSubtitlesPositionChange(item.value)}
                >
                  <span className={styles.label}>{item.label}</span>
                </div>
              ))
            }
          </div>
          </>
        )
      }
    </div>
  );


const SettingPanelControl = ({
  playbackRateValue,
  subtitlesFontSizeValue,
  onSubtitlesFontSizeChange,
  onPlaybackRateChange,
  isShowSubtitleSetting,
  subtitlesPositionValue,
  onSubtitlesPositionChange
}) => {

  const [{ isShowPanel }, setState] = useSetState({
    isShowPanel: false
  });

  const togglePanelShow = useCallback(() => setState({ isShowPanel: !isShowPanel }), [isShowPanel, setState])

  return (
    <ControlButton
      active={isShowPanel}
      className={styles.settingPanelControl}
      onClick={() => togglePanelShow()}
    >
      <Icon type="text" name="cog" />
      <Panel
        isShow={isShowPanel}
        playbackRateValue={playbackRateValue}
        subtitlesFontSizeValue={subtitlesFontSizeValue}
        onSubtitlesFontSizeChange={onSubtitlesFontSizeChange}
        onPlaybackRateChange={onPlaybackRateChange}
        isShowSubtitleSetting={isShowSubtitleSetting}
        subtitlesPositionValue={subtitlesPositionValue}
        onSubtitlesPositionChange={onSubtitlesPositionChange}
      />
    </ControlButton>
  )
};

export default SettingPanelControl;
