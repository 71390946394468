import React from 'react';
import Icon from 'components/Icon';
import { useStore, StoreTypes } from 'context';

import styles from './index.module.scss';

const LiveStreamEmojiCounts = () => {
  const [{ rewardInfo }] = useStore(StoreTypes.course);
  const { fireworks, good, like, trophy } = rewardInfo || {};
  return (
    <div className={styles.emojiCounts}>
      <div className={styles.emojiCount}>
          <div><Icon name="FireworksIcon" /></div>
          <div className={styles.countText}>{fireworks ? fireworks: 0}</div>
      </div>
      <div className={styles.emojiCount}>
          <div><Icon name="GoodIcon" /></div>
          <div className={styles.countText}>{good ? good: 0}</div>
      </div>
      <div className={styles.emojiCount}>
          <div><Icon name="LikeIcon" /></div>
          <div className={styles.countText}>{like ? like: 0}</div>
      </div>
      <div className={styles.emojiCount}>
          <div><Icon name="TrophyIcon" /></div>
          <div className={styles.countText}>{trophy ? trophy: 0}</div>
      </div>
    </div>
  )
}


export default LiveStreamEmojiCounts;