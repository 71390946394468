import { useEnhanceReducer } from 'customHooks/enhanceReducer';
import * as types from 'constants/actionTypes';
import { ReaderToolType } from 'constants/ReaderTools';
import { FontSizeType } from 'constants/fontSizeType';

export const initState = {
  fontSize: FontSizeType.Medium,
  svgContent: '',
  popWindowScale: 1,
  synopsisList: [],
  synopsisTab: 0,
  wordLearningGrid: {
    playbackRate: 1
  },
  isShowAnswer: false,
  toolType: ReaderToolType.Drag
};

const extendedContentReducer = (state, action) => {
  switch (action.type) {
    case types.SET_EXTENDED_CONTENT_FONT_SIZE:
      const { fontSize } = action.payload;
      return { ...state, fontSize };
    case types.SET_EXTENDED_CONTENT_SYNOPSIS_LIST:
      const { synopsisList } = action.payload
      return { ...state, synopsisList };
    case types.SET_EXTENDED_CONTENT_SYNOPSIS_TAB:
      const { synopsisTab } = action.payload;
      return { ...state, synopsisTab };
    case types.CHANGE_EXTENDED_CONTENT_SCALE:
      const { popWindowScale } = action.payload;
      return { ...state, popWindowScale }
    case types.IMPORT_EXTENDED_CONTENT_CANVAS_SVG:
      return { ...state, svgContent: action.svgContent };
    case types.SET_EXTENDED_CONTENT_WORD_LEARNING_GRID_PLAYBACK_RATE:
      const { playbackRate } = action.payload;
      return { ...state, wordLearningGrid: { ...state.wordLearningGrid, playbackRate } }
    case types.SET_EXTENDED_CONTENT_SHOW_ANSWERS:
      const { isShowAnswer } = action.payload;
      return { ...state, isShowAnswer };
    case types.SET_EXTENDED_CONTENT_READER_TOOL_TYPE:
      const { toolType } = action.payload;
      return { ...state, toolType };
    default:
      return state;
  }
};

const useExtendedContentReducer = () =>
  useEnhanceReducer(extendedContentReducer, initState);

export default useExtendedContentReducer;
