import { useAudioVideo } from 'amazon-chime-sdk-component-library-react';

const DATA_MESSAGE_TOPIC = 'orderStudent';
const DATA_MESSAGE_LIFETIME_MS = 300000;

export const useOrderStudent = () => {


  const audioVideo = useAudioVideo();

  const sendDataMessage = (attendeeId, isActive) => {
    audioVideo.realtimeSendDataMessage(
      DATA_MESSAGE_TOPIC,
      { attendeeId, isActive },
      DATA_MESSAGE_LIFETIME_MS
    );
  }

  const orderStudent = (attendeeId) => sendDataMessage(attendeeId,true)
  const unOrderStudent = (attendeeId) => sendDataMessage(attendeeId,false)

  const receiveDataMessageHandler = callback => dataMessage => {
    callback(dataMessage.json())
  }

  const subscribeOrderStudent = callback => {
    if(!audioVideo) return;
    audioVideo.realtimeSubscribeToReceiveDataMessage(
      DATA_MESSAGE_TOPIC,
      receiveDataMessageHandler(callback)
    )
  }


  return { orderStudent, unOrderStudent, subscribeOrderStudent }
}