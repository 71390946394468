import React, { useEffect } from 'react';
import { useToggleLocalMute, useLocalVideo, PreviewVideo, useMeetingManager, useSelectVideoQuality, useAudioVideo } from 'amazon-chime-sdk-component-library-react';
import { useStore, StoreTypes } from 'context';
import LiveStreamStatusMeter from 'components/LiveStreamStatusMeter';
import styles from './index.module.scss';
import classnames from 'classnames';
import Icon from 'components/Icon';
import { ReaderEvent } from 'events/EventTypes';
import { EventBus } from 'events/EventBus';
import { Roles } from 'constants/role';
import { useChimeError } from 'customHooks/chimeError'

const LiveStreamLocalVideo = ({ className, streaming = false }) => {
  const [{ isFullVideo }] = useStore(StoreTypes.course);
  const [{ role }] = useStore(StoreTypes.user);
  const meetingManager = useMeetingManager();
  const selectVideoQuality = useSelectVideoQuality();
  const selfAttendeeId = meetingManager.configuration.credentials.attendeeId;
  const externalUserId = meetingManager.configuration.credentials.externalUserId;
  const userId = externalUserId.split('$')[0];
  const [{ name: userName }] = useStore(StoreTypes.user);

  const { isVideoEnabled, toggleVideo } = useLocalVideo();
  const { muted, toggleMute } = useToggleLocalMute();
  const audioVideo = useAudioVideo();
  const { processDeviceError, showNotification } = useChimeError();

  const restartLocalVideoTile = async (audioVideo, meetingManager) => {
    const observer = {
      videoTileDidUpdate: tileState => {
        // Ignore a tile without attendee ID and other attendee's tile.
        if (!tileState.boundAttendeeId || !tileState.localTile) {
          return;
        }
        if (!tileState.active) {
          (async () => {
            await audioVideo.chooseVideoInputDevice(meetingManager.selectedVideoInputDevice);
            audioVideo.startLocalVideoTile();
            audioVideo.removeObserver(observer);
          })();
        }
      }
    };
    audioVideo.addObserver(observer);
    audioVideo.stopLocalVideoTile();
  };


  useEffect(() => {
    if (!audioVideo)
      return;

    isFullVideo ? selectVideoQuality('720p') : selectVideoQuality('360p');

    //重起攝影機才會生效
    (async () => {
      if (streaming) {
        if (isVideoEnabled) {
          await restartLocalVideoTile(audioVideo, meetingManager);
          return;
        }
        else {

          try {
            await toggleVideo();
          }
          catch (error) {
            processDeviceError(`攝影機`, error);
          }
        }
      }
      else {
        if (isVideoEnabled) {
          try {
            toggleVideo();
          }
          catch (error) {
            processDeviceError(`攝影機`, error);
          }
        }
      }
    })();

  }, [isFullVideo, selectVideoQuality, streaming])

  useEffect(() => {
    if (streaming && muted) {
      toggleMute();
    }

    if (!streaming && !muted) {
      toggleMute();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [streaming])


  const videoFullScreenHandler = (isFull) => {
    EventBus.emit({ event: ReaderEvent.sendFullVideoEvent, payload: { isFullVideo: isFull } });
  }

  return <div className={classnames(styles.localVideo, className)}>
    {/* {
      streaming && isVideoEnabled && <LocalVideo className={styles.video} />
    } */}
    <PreviewVideo className={styles.video} />
    {
      role === Roles.ONECLASS_TEACHER && <div>

        <div
          className={classnames(styles.offFullVideoBtn, { [styles.offFullVideoBtnHide]: !isFullVideo })}
          onClick={() => videoFullScreenHandler(false)}
        >
          <Icon name="OffFullVideoOver" />
          <Icon name="OffFullVideoOut" />
        </div>
        <div className={classnames(styles.fullVideoBtn, { [styles.fullVideoBtnHide]: isFullVideo })}
          onClick={() => videoFullScreenHandler(true)}
        >
          <Icon name="FullVideoOver" />
          <Icon name="FullVideoOut" />
        </div>
      </div>
    }
    {
      selfAttendeeId && <LiveStreamStatusMeter userName={userName} attendeeId={selfAttendeeId} />
    }
  </div>
};


export default LiveStreamLocalVideo;