import React, { useEffect, useCallback,useState } from 'react';
import styles from './index.module.scss';
import DialogModule from 'components/Dialogs/'
import Button from '@material-ui/core/Button';
import { useStore, StoreTypes } from 'context';
import { Roles } from 'constants/role';
import * as types from 'constants/actionTypes';

const StartCourseDialog = ({ }) => {
    const [{ }, readerDispatch] = useStore(StoreTypes.reader);
    const [{ openStartCourseDialog }, courseDispatch] = useStore(StoreTypes.course);
    const [{ userId, name: userName, role, token }] = useStore(StoreTypes.user);
    const [isOpen,setIsOpen]=useState(openStartCourseDialog)

    const showLiveStreamComponent = useCallback(() => {
        setIsOpen(false)
        window.location.reload(false);
    }, [])

    const deleteShelfDialogAction = <div id="dialog" className={styles.ok}>
        <Button
            style={{
                border: "solid",
                color:"white",
                backgroundColor: "cadetblue",
                borderWidth: 2
            }}
            onClick={() => {
                showLiveStreamComponent()
            }}
            color="primary"
        >確定</Button>
    </div>;
    

    return (
        <DialogModule
            open={isOpen}
            content={'上課時間到了喔！'}
            style={{ paddingBottom: '20px' ,display:'flex',justifyContent:'center',zIndex: 10000}}
            action={deleteShelfDialogAction}
        />
    )
};

export default StartCourseDialog;