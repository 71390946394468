import { lightTheme } from 'amazon-chime-sdk-component-library-react';


 const theme = {
  ...lightTheme,
  buttons: {
    ...lightTheme.buttons,
    icon: {
      ...lightTheme.buttons.icon,
      active: {
        ...lightTheme.buttons.icon.active,
        bgd: "#4C99A5",
        shadow: "0 0 0 0.25rem #5EA4AE"
      },
      hover: {
        ...lightTheme.buttons.icon.hover,
        bgd: "#4C99A5",
        border: "0.03125rem solid #4C99A5"
      },
      focus: {
        ...lightTheme.buttons.icon.focus,
        border: "0.03125rem  solid #4C99A5",
        bgd: "#4C99A5",
        shadow: "none"
      },
      selected: {
        ...lightTheme.buttons.icon.selected,
        border: "0.03125rem  solid #4C99A5",
        bgd: "#4C99A5",
      }
    }
  },
  colors: {
    ...lightTheme.colors,
    primary: {
      ...lightTheme.colors.primary,
      main: "#4C99A5"
    }
  },
  controlBar: {
    ...lightTheme.controlBar,
    border: "0.03125rem solid #4C99A5",
    selected: {
      bgd: "#4C99A5",
      text: "#ffffff"
    }
  },
  popOver: {
    ...lightTheme.popOver,
    active: {
      ...lightTheme.popOver.active,
      itemBgd: "#4C99A5",
    },
  }
}

export default theme;