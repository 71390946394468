import React, { useState, useEffect } from 'react';
import Icon from 'components/Icon';
import styles from './index.module.scss';
import classnames from 'classnames'

const ColorSelector = ({ 
  value, 
  colors, 
  onChange = () => { }, 
  customizedColor = false, 
  transparentOption = false,
  opacity = 1
}) => {
  
  const [inColors,setState] = useState(true);

  useEffect(()=>{
    if(!value)return;
    const hasColor = colors.findIndex(color => ~value.indexOf(color)) > -1;
    setState(hasColor)
  },[colors, value])


  return (
      <div className={styles.colorSelector}>
        {
          transparentOption && (
            <div className={classnames(styles.colorItem, styles.transparent)} onClick={(e) => onChange('')}>
                <Icon type="svg" name="Ban"/>
            </div>
          )
        }
        {
          colors.map(color => 
            <div className={classnames(styles.colorItem, { [styles.active]: value && ~value.indexOf(color) })}>
              <div 
                key={color} 
                className={classnames(styles.color, { [styles.blackColor]: color === '#000000' })} 
                style={{ backgroundColor: color }} onClick={() => onChange(color)}
              />
            </div>
          )
        }
        {
          customizedColor && (
            <div className={classnames(styles.color, styles.customizedColor, { [styles.active]: !inColors })}>
              <label>
                <input type="color" onChange={(e) => onChange(e.target.value)} />
              </label>
            </div>
          )
        }
      </div>
  )
}

export default ColorSelector;
