import React, { useState, useCallback, useEffect, useRef } from 'react';
// import FontAwesome from 'react-fontawesome';
import classnames from 'classnames';
import styles from './index.module.scss';
import { useStore, StoreTypes } from 'context';
import { groupPages } from 'util/book';
import BookPage from 'components/BookPage';
import { InteractiveObjectView } from 'components/InteractiveObjectView';
import { useFetchBooks } from 'customHooks/book';
import { BookFlipType } from 'constants/flipTypes';
import { CanvasSVGObjectView } from 'components/CanvasSVGObjectView';
import { useInteractiveObjectContentCommandFactory } from 'customHooks/InteractiveObjectContentCommands/commandFactory';
import { useEvent } from 'events/EventBus';
import { InteractiveObjectEvent } from 'events/EventTypes';


const getCanvasSvg = str => {
  str = str.replace('<svg', '<svg preserveAspectRatio="xMidYMid"');
  return str;
};

const InitInteractiveObjectState = { version: 0, state: [] };

const BookView = ({ book }) => {
  const [bookData, setBookData] = useState({});
  const [{ svgShow, isActive, canvasSVG }] = useStore(StoreTypes.canvas);

  const [{ isDoublePageMode, isMarkModeShow, pageIndex }] = useStore(
    StoreTypes.reader
  );
  const [{ annotationId }] = useStore(
    StoreTypes.annotation
  );
  const [{ bookContent, interactiveObjectSVG, interactiveObjectJSON }] = useStore(StoreTypes.books);
  const [interactiveObjectState, setInteractiveObjectState] = useState(InitInteractiveObjectState);
  const { fetchBook } = useFetchBooks();
  const { bookId, pageInfos, LRFlip } = book;
  const bookRef = useRef();
  const CommandFactory = useInteractiveObjectContentCommandFactory();

  const SetInteractiveObjectStateEventHandler = useCallback(
    async ({
      json
    }) => {
      if(!json)return;
      const command = CommandFactory.createCommand(json);
        command &&
          command.execute({
            json,
            pageIndex,
            isDoublePageMode,
            interactiveObjectState,
            setInteractiveObjectState
          });
    },
    [CommandFactory, pageIndex, isDoublePageMode, interactiveObjectState]
  );

  useEvent({ event: InteractiveObjectEvent.SetInteractiveObjectStateEvent }, SetInteractiveObjectStateEventHandler);

  useEffect(() => {
    const data = groupPages(bookContent, isDoublePageMode);
    setBookData(data);
    // reset interactive object state
    setInteractiveObjectState(InitInteractiveObjectState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [LRFlip, bookContent, isDoublePageMode]);

  const bookPageRenderer = useCallback(
    ({ index, key }) => {
      const page = bookData[index];
      let pages = [];
      const threshold = 3;
      if (isDoublePageMode) {
        let startPage = Math.max(0, index * 2 - threshold * 2);
        let endPage = Math.min(
          index * 2 + 1 + threshold * 2,
          pageInfos.length - 1
        );
        for (let i = startPage; i <= endPage; ++i) {
          pages.push(i);
        }
      } else {
        let startPage = Math.max(0, index - threshold);
        let endPage = Math.min(index + threshold, pageInfos.length - 1);
        for (let i = startPage; i <= endPage; ++i) {
          pages.push(i);
        }
      }
      pages = pages.filter(page => !bookContent[page]);
      if (pages.length > 0) {
        fetchBook({ bookId, pages });
      }

      return page ? (
        <div
          className={classnames({
            [styles.reverse]: LRFlip === BookFlipType.RIGHT_TO_LEFT,
            [styles.markMode]: isMarkModeShow
          })}
        >
          {page.map(item => {
            return item ? (
              <div className={styles.page} key={item.pageIndex} ref={bookRef}>
                <BookPage
                  content={item.svg}
                  bookInfo={book}
                  pageIndex={item.pageIndex}
                />

                {/* 新增的svg層  start */}
                {interactiveObjectSVG[item.pageIndex] && (
                  <InteractiveObjectView
                    svgString={interactiveObjectSVG[item.pageIndex]}
                    pageIndex={item.pageIndex}
                    bookInfo={book}
                    interactiveObjectState={interactiveObjectState}
                    setInteractiveObjectState={setInteractiveObjectState}
                    interactiveObjectJSON={interactiveObjectJSON}
                  />
                )}
                {/* 新增的svg層  end */}
              </div>
            ) : (
                <div className={styles.page} key="blank" />
              );
          })}
           {canvasSVG[index] && !isActive && (
            <CanvasSVGObjectView annotationId={annotationId} viewIndex={index} svgString={getCanvasSvg(canvasSVG[index])} />
          )}
        </div>
      ) : (
          <div key={key} />
        );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [LRFlip, annotationId, book, bookContent, bookData, bookId, canvasSVG, fetchBook, interactiveObjectSVG, interactiveObjectState, isActive, isDoublePageMode, isMarkModeShow, pageInfos.length]
  );

  return (
    <div className={styles.bookViewContainer}>
      {book && bookPageRenderer({ index: pageIndex, key: pageIndex })}
    </div>
  );
};

export default BookView;
