import React, { useCallback } from 'react';
import Button from 'components/Button/index';
import Icon from 'components/Icon';
import { PainterType, LineType } from 'constants/painterTypes';
import styles from './index.module.scss';
import classnames from 'classnames';

const fillTypeData = [
  { value: 'hollow', label: '空心' },
  { value: 'solid', label: '實心' }
];

const ShapeSelector = ({
  onLineTypeChange = () => { }, 
  onChange, 
  painterType,
  lineType = PainterType.Line,
  onPainterChange = () => {}
}) => {

  const painterChangeHandler = useCallback(type=>{
    onPainterChange(type)
    type === PainterType.Pen && onLineTypeChange(LineType.Line)
  },[onLineTypeChange, onPainterChange])

  return (
    <div className={styles.shapeSelector}>
      <div className={styles.buttonGroup}>
        <div
          className={classnames(styles.button, { [styles.active]: painterType === PainterType.RightTriangle })}
          onClick={() => onChange(PainterType.RightTriangle)}
        >
          <Icon type="svg" name="RightTriangle" />
        </div>
        <div
          className={classnames(styles.button, { [styles.active]: painterType === PainterType.IsoscelesTriangle })}
          onClick={() => onChange(PainterType.IsoscelesTriangle)}
        >
          <Icon type="svg" name="IsoscelesTriangle" />
        </div>
        <div
          className={classnames(styles.button, { [styles.active]: painterType === PainterType.Circle })}
          onClick={() => onChange(PainterType.Circle)}
        >
          <Icon type="svg" name="Circle" />
        </div>
        <div
          className={classnames(styles.button, { [styles.active]: painterType === PainterType.Rectangle })}
          onClick={() => onChange(PainterType.Rectangle)}
        >
          <Icon type="svg" name="Square" />
        </div>
        <div
          className={classnames(styles.button, { [styles.active]: painterType === PainterType.Star })}
          onClick={() => onChange(PainterType.Star)}
        >
          <Icon type="svg" name="Star" />
        </div>
        <div
            className={classnames(styles.button,{
              [styles.active]: painterType === PainterType.Line
            })}
            onClick={() => painterChangeHandler(PainterType.Line)}
          >
            <Icon type="svg" name="Line" />
          </div>
          <div
            className={classnames(styles.button,{
              [styles.active]: painterType === PainterType.Pen,
              [styles.disabled]: lineType === LineType.Dash
            })}
            onClick={() => painterChangeHandler(PainterType.Pen)}
            disabled={lineType === LineType.Dash}
          >
            <Icon type="svg" name="Curve" />
          </div>
          <div
            className={classnames(styles.button,{
              [styles.active]: painterType === PainterType.Arrow
            })}
            onClick={() => painterChangeHandler(PainterType.Arrow)}
          >
            <Icon type="svg" name="LineArrow" />
          </div>
      </div>
    </div>
  );
};
export default ShapeSelector;
