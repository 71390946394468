import { useCallback } from 'react';
import { useStore, StoreTypes } from 'context';
import { ReaderToolType } from 'constants/ReaderTools';
import { BaseReaderStrategy } from './BaseReaderStrategy';
import { useSyncClassPreparationDispatcher } from 'customHooks/syncClassPreparation';

const Blacklist = [
    ReaderToolType.Activity,
    ReaderToolType.ExportInteractiveObject,
    ReaderToolType.SVG,
    //ReaderToolType.Select
];

export const usePrepareClassStrategy = () => {
    const [{ isActive }] = useStore(StoreTypes.canvas);
    const { syncClassPreparation } = useSyncClassPreparationDispatcher();

    const getReaderToolComponents = useCallback((readerToolComponents) => {
        return BaseReaderStrategy.getFilteredReaderToolComponents(readerToolComponents, Blacklist);
    }, []);

    const isReaderSwipeable = useCallback(() => {
        return !isActive;
    }, [isActive]);

    const isReaderSwitchPageAvailable = useCallback(() => {
        return true;
    }, []);

    const syncAnnotation = useCallback((id) => {
        syncClassPreparation(id);
    }, [syncClassPreparation]);

    const isInteractiveObjectTriggerable = useCallback(({ contentType }) => {
        return true;
    }, []);

    return {
        name: 'PrepareClassStrategy',
        getReaderToolComponents,
        isReaderSwipeable,
        isReaderSwitchPageAvailable,
        syncAnnotation,
        isInteractiveObjectTriggerable
    };
};
