import React, { useState, useEffect } from 'react';
import { RemoteVideo, useRosterState, useRemoteVideoTileState ,useMeetingManager} from 'amazon-chime-sdk-component-library-react';
import { Roles } from 'constants/role';
import { useStore, StoreTypes } from 'context/index';
import LiveStreamStatusMeter from 'components/LiveStreamStatusMeter';
import styles from './index.module.scss';
import { getAttendeeInfo } from 'customHooks/studentList';

 const LiveStreamRemoteVideo = React.memo(({ isFull }) => {
  const [attendeeIdInfo, setAttendeeIdInfo] = useState({});
  const { roster } = useRosterState();
  const { attendeeIdToTileId } = useRemoteVideoTileState();
  //const [{ name: userName }] = useStore(StoreTypes.user);
  const meetingManager = useMeetingManager();
  const meetingId = meetingManager.meetingId;

  useEffect(() => {
    (async()=>{
      const student = Object.values(roster).find(item => item.externalUserId.split("$")[1] === Roles.ONECLASS_STUDENT);
      if (student) {
        let studentInfo=getAttendeeInfo({meetingId,item:student})
        setAttendeeIdInfo(await Promise.resolve(studentInfo))
      }
    })()

  }, [roster,meetingId])

  return attendeeIdInfo && attendeeIdToTileId[attendeeIdInfo.chimeAttendeeId] ?
    <div className={styles.remoteVideo}>
      <RemoteVideo tileId={attendeeIdToTileId[attendeeIdInfo.chimeAttendeeId]} />
      {
        !isFull && <LiveStreamStatusMeter attendeeId={attendeeIdInfo.chimeAttendeeId} userName={attendeeIdInfo.name}/>
      }
    </div>
    : null
});


export default LiveStreamRemoteVideo;