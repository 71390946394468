import React from 'react';
import Icon from 'components/Icon';
import styled from 'styled-components';

const StyledTool = styled.div`
  position: relative;
  display: flex;
	justify-content: center;
	align-items: center;
	width: 32px;
	height: 100%;
	margin: 0 8px;
	cursor: pointer;
`

const StyledIcon = styled(Icon)`
  svg path {
    ${({ fill, isActive }) => isActive && `fill: ${fill}`}
  }
`;

const ReaderToolButton = React.forwardRef((({ 
  iconName,
  onClick, 
  isActive = false, 
  activeColor = '#EC7963',
  children
},ref) => { 
  return (
    <StyledTool onClick={onClick} ref={ref}>
      <StyledIcon 
        type="svg" 
        name={iconName} 
        fill={activeColor} 
        isActive={isActive}
      />
      {children}
    </StyledTool>
  )
}));

export default ReaderToolButton;