
import { PreparationAPI } from 'api/PreparationsAPI'
import { EventBus } from 'events/EventBus';
import { ReaderEvent } from 'events/EventTypes';

function CloudPreparationRepository() { }

CloudPreparationRepository.prototype.getPreparationList = async ({
    params,
    token
}) => {
    try {
        const response = await PreparationAPI.get(
            `${process.env.REACT_APP_API_DOMAIN}/preparations`,
            params,
            token
        );
        if (response.status === 'success') {
            return response.data;
        }
    } catch (err) {
        console.log(`GET /preparations error ${err}`);
    }
    return [];
};

CloudPreparationRepository.prototype.getPreparationById = async ({
    id,
    token
}) => {
    try {
        const response = await PreparationAPI.getById(
            `${process.env.REACT_APP_API_DOMAIN}/preparations/${id}`,
            token
        );
        if (response.status === 'success') {
            return response.data;
        }
    } catch (err) {
        console.log(`GET /preparations/:id error ${err}`);
    }
    return null;
};

CloudPreparationRepository.prototype.getPreparationAnnotations = async ({
    id,
    token
}) => {
    try {
        let res = await PreparationAPI.getById(
            `${process.env.REACT_APP_API_DOMAIN}/preparations/${id}/annotations/brief`,
            token
        );
        let annotations = [];
        let progressMax = 100
        let progressCount = 0
        if (res.status === 'success') {
            if (res.data.length > 0) {
                progressMax = res.data.length
                let range = 10;
                let tempArr = []
                let arr = []
                for (let i = 0; i < res.data.length; i++) {
                    tempArr.push(res.data[i])
                    if (tempArr.length === range) {
                        arr.push(tempArr)
                        tempArr = []
                    }
                }
                arr.push(tempArr)

                annotations = await arr.reduce((promise, indexArr) => {
                    return promise.then(async (results) => {
                        let annotation = []
                        await Promise.all(indexArr.map(pageIndex => PreparationAPI.getById(
                            `${process.env.REACT_APP_API_DOMAIN}/preparations/${id}/annotations/${pageIndex}`,
                            token
                        ).then(data => {
                            EventBus.emit({ event: ReaderEvent.SetProgressEvent, payload: {progress:progressCount / progressMax * 100} })
                            progressCount++
                            annotation.push(data.data)
                        })))
                        return [...results, ...annotation]

                        // return PreparationAPI.getById(`${process.env.REACT_APP_API_DOMAIN}/preparations/${id}/annotations/${pageIndex}`, token)
                        //     .then(annotation => [...results, annotation.data]);
                    });
                }, Promise.resolve([]));
            }
        }
        return annotations;
    } catch (err) {
        console.log(`GET /preparations/:id/annotations/:page error ${err}`);
    }
    return null;
};

CloudPreparationRepository.prototype.copyPreparationAnnotations = async ({
    annotation,
    token
}) => {
    try {
        let res = await PreparationAPI.post(
            `${process.env.REACT_APP_API_DOMAIN}/preparations/${annotation.id}/copy`,
            annotation,
            token
        );
        if (res.status === 'success') {
            return res.data;
        } else {
            return null
        }
    } catch (err) {
        console.log(`GET /preparations/:id/annotations/:page error ${err}`);
    }
    return null;
}

CloudPreparationRepository.prototype.updatePreparation = async ({
    id,
    annotation,
    token
}) => {
    try {
        const response = await PreparationAPI.put(
            `${process.env.REACT_APP_API_DOMAIN}/preparations/${id}`,
            annotation,
            token
        );
        if (response.status === 'success') {
            return response.data;
        }
    } catch (err) {
        console.log(`PUT /preparations/:id error ${err}`);
    }
    return null;
};

export default new CloudPreparationRepository();
