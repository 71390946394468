import React, { useRef, useCallback, useEffect, useState } from 'react';
import classnames from 'classnames';
import styles from './index.module.scss';
import { useStore, StoreTypes } from 'context';
import { ReaderZoomType } from 'constants/ReaderTools';
import { ReaderToolsEvent } from 'events/EventTypes';
import { EventBus } from 'events/EventBus';
import * as types from 'constants/actionTypes';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { useCourse } from 'customHooks/course'
import { debounce } from 'util/debounce';
import { Roles } from 'constants/role';

let scaleTemp = 1;

export const PinchPanContainer = ({ index, children }) => {

  const [{ style: { width, height } }
  ] = useStore(StoreTypes.books);
  const [{ role }] = useStore(StoreTypes.user);
  const [
    { offsetX, offsetY, scale, isMarkModeShow, zoomType },
    readerDispatch
  ] = useStore(StoreTypes.reader);
  const [{ firestore, remoteZoomInfo }] = useStore(StoreTypes.course);
  const [{ isActive }] = useStore(StoreTypes.canvas);
  const [{ wheelScale, wheelX, wheelY }, setWheelInfo] = useState({ wheelScale: 1, wheelX: 0, wheelY: 0 });
  const [transformWrapperParams, setTransformWrapperParams] = useState({});
  const ref = useRef(null);
  const updateScaleTimeout = useRef(null);
  const [, { broadcastEvent }] = useCourse();

  const panningHandler = useCallback(e => {
    if (e.previousScale === e.scale && e.positionX === 0 && e.positionY === 0) return;
    const scaleX = (e.positionX / ((width * e.scale) - width))
    const scaleY = (e.positionY / ((height * e.scale) - height))
    const zoomInfo = {
      scaleX,
      scaleY,
      scale: e.scale,
      bookWidth: width,
      bookHeight: height,
      del: "="
    }

    EventBus.emit({
      event: ReaderToolsEvent.ZoomToolEvent,
      payload: { type: ReaderZoomType.PanZoom }
    });

    broadcastEvent({ eventType: ReaderToolsEvent.SetRemoteZoomInfoEventHandler, payload: { zoomInfo, remote: true } });

    const { positionX, positionY, scale } = e;
    const params = {
      positionX,
      positionY,
      scale
    }

    readerDispatch({
      type: types.SET_SCALE_INFO,
      offsetX: positionX,
      offsetY: positionY,
      scale
    });

    setTransformWrapperParams(params)
    readerDispatch({ type: types.SET_AREA_ZOOM_INTERACTIVE_OBJECTS, areaZoomInteractiveObjects: null })
  }, [width, height, broadcastEvent, readerDispatch]);

  useEffect(() => {
    if (remoteZoomInfo) {
      let {
        del,
        scaleX,
        scaleY,
        bookWidth: remoteBookWidth,
        bookHeight: remoteBookHeight
      } = remoteZoomInfo;

      if (del === "+") {
        scaleTemp = wheelScale + 0.04
      } else if (del === "-") {
        scaleTemp = wheelScale - 0.04
      }
      if (scaleTemp <= 1) {
        scaleTemp = 1;
      }
      if (!scaleX) {
        scaleX = 0;
      }
      if (!scaleY) {
        scaleY = 0;
      }

      let params = {
        positionX: (width * scaleTemp - width) * scaleX * (width / remoteBookWidth),
        positionY: (height * scaleTemp - height) * scaleY * (height / remoteBookHeight),
        scale: scaleTemp
      };
      setTransformWrapperParams(params)
      setWheelInfo({ wheelX: (width * scaleTemp - width) * scaleX, wheelY: (height * scaleTemp - height) * scaleY, wheelScale: scaleTemp });
    }

  }, [remoteZoomInfo, width, height, wheelScale])


  const handler = useCallback(event => {
    event.stopImmediatePropagation();
    return true;
  }, []);

  useEffect(() => {
    if (ref.current) {
      if (isMarkModeShow) {
        ref.current.onmousemove = handler;
        ref.current.onwheel = handler;
      } else {
        ref.current.onmousemove = null;
        ref.current.onwheel = null;
      }
    }
  }, [isMarkModeShow, handler]);

  useEffect(() => {
    if (ref.current) {
      ref.current.style.cursor = `inherit`;
      //ref.current.style.transform = `translate3d(${offsetX}px, ${offsetY}px, 0px) scale(${scale})`;
    }

    if (offsetX !== undefined) {
      scaleTemp = scale
      setWheelInfo({ wheelScale: scale, wheelX: offsetX, wheelY: offsetY });
    }
  }, [scale, offsetX, offsetY]);

  const wheelHandler = useCallback(e => {
    const scaleX = (e.positionX / ((width * e.scale) - width))
    const scaleY = (e.positionY / ((height * e.scale) - height))
    EventBus.emit({
      event: ReaderToolsEvent.ZoomToolEvent,
      payload: { type: ReaderZoomType.WheelZoom }
    });
    const zoomInfo = {
      scaleX: scaleX ? scaleX : 1,
      scaleY: scaleY ? scaleY : 1,
      scale: e.scale,
      bookWidth: width,
      bookHeight: height,
      del: "="
    }
    broadcastEvent({ eventType: ReaderToolsEvent.SetRemoteZoomInfoEventHandler, payload: { zoomInfo, remote: true } });

    const { positionX, positionY, scale } = e;
    const params = {
      positionX,
      positionY,
      scale
    }

    readerDispatch({
      type: types.SET_SCALE_INFO,
      offsetX: positionX,
      offsetY: positionY,
      scale
    });
    setTransformWrapperParams(params)
    readerDispatch({ type: types.SET_AREA_ZOOM_INTERACTIVE_OBJECTS, areaZoomInteractiveObjects: null })

  }, [width, height, broadcastEvent, readerDispatch]);

  const zoomEnable = (role === Roles.TUTOR_USER || role === Roles.ONECLASS_STUDENT) ? false : true;

  useEffect(() => {
    let params = {};
    switch (zoomType) {
      case ReaderZoomType.LeftTop:
      case ReaderZoomType.RightTop:
      case ReaderZoomType.LeftBottom:
      case ReaderZoomType.RightBottom:
      case ReaderZoomType.RangeZoom:
      case ReaderZoomType.AreaZoom:
        params = {
          positionX: offsetX,
          positionY: offsetY,
          scale: scale
        }
        break;
      case ReaderZoomType.OriginZoom:
        params = {
          positionX: 0,
          positionY: 0,
          scale: 1
        }
        break;
      default:
        break;
    }

    setTransformWrapperParams({ ...params })
  }, [readerDispatch, zoomType, scale, offsetX, offsetY])

  return (
    <div className={styles.pinchPanContainer}>
      <TransformWrapper
        doubleClick={{ disabled: true }}
        options={{
          minScale: 1,
          maxScale: 16
        }}
        scalePadding={{
          disabled: true
        }}
        pan={{
          disabled: isActive || transformWrapperParams.scale === 1,
          paddingSize: 0,
          velocity: false,
          velocityEqualToMove: false,
          velocitySensitivity: 0,
          velocityBaseTime: 0,
          animationTime: 0,
          velocityMinSpeed: 0
        }}
        wheel={{
          step: 250
        }}
        onPanning={panningHandler}
        onWheel={wheelHandler}
        zoomIn={{ disabled: !zoomEnable }}
        zoomOut={{ disabled: !zoomEnable }}
        {...transformWrapperParams}
      >
        {
          () => {
            return (
              <TransformComponent>
                <div
                  ref={v => (ref.current = v)}
                  className={classnames(styles.swipeContainer)}
                >
                  {children}
                </div>
              </TransformComponent>
            )
          }
        }
      </TransformWrapper>
    </div >
  )
}