import React, { useState, useEffect } from 'react';
import {
  ControlBar,
  Cog,
  ContentShareControl,
  AudioOutputControl,
  ControlBarButton,
  ListHandRaise,
  useRosterState,
  useVideoInputs,
  useAudioInputs,
  useLocalVideo,
  useSelectVideoInputDevice,
  useSelectAudioInputDevice,
  Camera,
  Microphone,
  useToggleLocalMute
} from 'amazon-chime-sdk-component-library-react';
import { useStore, StoreTypes } from 'context';
import { EventBus } from 'events/EventBus';
import { ToolsEvent } from 'events/EventTypes';
import { Roles } from 'constants/role';
import classnames from 'classnames';
import styles from './index.module.scss';
import { useChimeError } from 'customHooks/chimeError'

const videoInputConfig = {
  additionalDevices: false,
};

const audioInputConfig = {
  additionalDevices: false,
};

export const isOptionActive = (
  meetingManagerDeviceId,
  currentDeviceId
) => {
  if (currentDeviceId === 'none' && meetingManagerDeviceId === null) {
    return true;
  }
  return currentDeviceId === meetingManagerDeviceId;
};

const AudioInputControl = ({ label = 'Audio', onClick }) => {
  const { devices, selectedDevice } = useAudioInputs(audioInputConfig);
  const selectDevice = useSelectAudioInputDevice();
  const { muted } = useToggleLocalMute();
  const { processDeviceError, showNotification } = useChimeError();

  if (devices.length == 0 || devices.some(item => item.label.length == 0)) {
    processDeviceError('麥克風', "PermissionDeniedError");
  }
  const dropdownOptions = devices.map((device) => ({
    children: <span>{device.label}</span>,
    checked: isOptionActive(selectedDevice, device.deviceId),
    onClick: () => selectDevice(device.deviceId),
  }));

  return (
    <ControlBarButton
      icon={<Microphone disabled={!muted} />}
      onClick={onClick}
      label={label}
      popOver={dropdownOptions}
    />
  );
};

const VideoInputControl = ({ label = 'Video', onClick }) => {
  const { devices, selectedDevice } = useVideoInputs(videoInputConfig);
  const selectDevice = useSelectVideoInputDevice();
  const { isVideoEnabled } = useLocalVideo();
  const { processDeviceError, showNotification } = useChimeError();

  if (devices.length == 0 || devices.some(item => item.label.length == 0)) {
    processDeviceError('攝影機', "PermissionDeniedError");
  }

  const dropdownOptions = devices.map((device) => ({
    children: <span>{device.label}</span>,
    checked: isOptionActive(selectedDevice, device.deviceId),
    onClick: () => selectDevice(device.deviceId),
  }));

  return (
    <ControlBarButton
      icon={<Camera disabled={!isVideoEnabled} />}
      onClick={onClick}
      label={label}
      popOver={dropdownOptions}
    />
  );
};

const LiveStreamControlBar = ({ isOpen }) => {
  const [{ chimeRole, isMultiple }] = useStore(StoreTypes.course);

  const [{ userId }] = useStore(StoreTypes.user);
  const [{ toolsIsControl }] = useStore(StoreTypes.reader);

  const isTeacher = chimeRole === Roles.ONECLASS_TEACHER

  const style = `
    display: ${isOpen ? 'block' : 'none'};
    position: absolute;
    left: 50%;
    bottom: 120%;
    transform: translateX(-50%);
    height: auto;
    svg[data-testid="popover-check"] { 
      color: #000;
    }
  `;

  const [studentId, setStudentId] = useState('');
  const { roster } = useRosterState();

  useEffect(() => {
    const student = Object.values(roster).find(item => item.externalUserId.split("$")[1] === Roles.ONECLASS_STUDENT);
    if (student) {
      setStudentId(student.externalUserId)
    }

  }, [roster])

  const controlButtonProps = {
    icon: <ListHandRaise />,
    onClick: () => {
      EventBus.emit({
        event: ToolsEvent.ToolsIsControl,
        payload: { userId : toolsIsControl ? studentId : userId }
      });
    },
    label: '',
    isSelected: toolsIsControl
  };
  
  const { toggleVideo } = useLocalVideo();
  const { toggleMute } = useToggleLocalMute();

  const clickHandler = () => {
    isTeacher && toggleVideo();
  }

  const clickAudioInputHandler =()=>{
    isTeacher && toggleMute();
  }

  return (
    <ControlBar showLabels layout="left" css={style}>
      <AudioInputControl muteLabel="" unmuteLabel="" onClick={clickAudioInputHandler} />
      <AudioOutputControl label="" />
      <VideoInputControl label="" onClick={clickHandler}/>
      { 
        isTeacher && (
          <>
            <ContentShareControl label="" /> 
            {
              !isMultiple &&   <ControlBarButton {...controlButtonProps} />
            }
          </>
        )
      }
    </ControlBar>
  );
}


const LiveStreamSettingPopOver = () => {

  const [isOpen,setOpen] = useState();

  const togglePopOverHandler = () => {
    setOpen(isOpen => !isOpen)
  }

  return (
    <div className={classnames(styles.liveStreamSettingPopOver, { [styles.isOpen]: isOpen })}>
      <LiveStreamControlBar isOpen={isOpen} />
      <Cog width="2rem" onClick={togglePopOverHandler}/>
    </div>
  )
};


export default LiveStreamSettingPopOver;