import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useVideo } from 'customHooks/mediaElement';
import useModal from 'components/common/Modal/useModal';
// import Playlist from 'components/common/Player/Playlist';
import SubtitleControl from 'components/common/Player/SubtitleControl';
import VolumeBar from 'components/common/Player/VolumeBar';
import TimeInfo from 'components/common/Player/TimeInfo';
import ProcessBar from 'components/common/Player/ProcessBar';
import ControlButton from 'components/common/Player/ControlButton';
import Subtitles from 'components/common/VideoPlayer/Subtitles';
import SettingPanelControl from 'components/common/VideoPlayer/SettingPanelControl';
import TabList from 'components/common/Player/TabList';
import Icon from 'components/Icon';
import QuestionPanel from 'components/common/Player/QuestionPanel';
import { useBoolean } from 'customHooks/boolean';
import useSetState from 'customHooks/setState';
import { useOutsideAlerter } from 'customHooks/outsideAlerter';
import { useStore, StoreTypes } from 'context';
import * as types from 'constants/actionTypes';
import { EventBus, EventBusType } from 'events/EventBus';
import PainterPanel from 'components/Painter/PainterPanel';
import { ReaderToolsEvent, PainterEvent, ReaderEvent } from 'events/EventTypes';
import { PainterToolType } from 'constants/painterTypes';
import { ReaderToolType } from 'constants/ReaderTools';
import { PainterMode } from 'constants/painterModes';
import { ExtendedContentEvent } from 'components/ExtendedContent/ExtendedContentEvent';
import { ExtendedContentCanvas } from 'components/ExtendedContent/ExtendedContentCanvas';
import classnames from 'classnames';
import styles from './index.module.scss';
import { useUpdateUserSettings } from 'customHooks/userSetting';

const eventBusType = EventBusType.ExtendedContent;

const PanelState = {
  None: 'None',
  Pen: 'Pen'
};

const getCanvasSvg = str => {
  str = str.replace('<svg', '<svg preserveAspectRatio="xMidYMid"');
  return str;
};

const VideoPlayer = ({
  className,
  src,
  poster,
  control = false,
  playlist = [],
  seekList,
  questionList,
  subtitles,
  onPlaylistChange = () => { }
}) => {


  const [{ videoSetting }, userSettingsDispatch] = useStore(StoreTypes.settings);
  const [, extendedContentDispatch] = useStore(StoreTypes.extendedContent);
  const [currentLanguage, setLanguage] = useState(['en']);
  const [isShowVolumeBar, setShowVolumeBarState] = useState(false);
  const [isShowTabList, { toggle: toggleTabList, setFalse: closeTabList }] = useBoolean();
  const [{
    questionPanelCloseDelaying,
    questionPanelShowing,
    currentQuestionIndex,
    questionTimeList,
    curserState,
  },
    setState] = useSetState({
      questionPanelCloseDelaying: false,
      questionPanelShowing: false,
      currentQuestionIndex: 0,
      questionTimeList: [],
      curserState: 'Drag',
    });
  const {
    element: video,
    state,
    controls,
    activeCue
  } = useVideo(
    <video src={src} playsInline crossOrigin="anonymous" poster={poster}>
      {subtitles.map(subtitle => (
        <track
          key={subtitle.language}
          kind="subtitles"
          label={subtitle.language}
          srcLang={subtitle.language}
          src={subtitle.src}
        />
      ))}
    </video>
  );

  const updateUserSettings = useUpdateUserSettings();

  useEffect(() => {
    setLanguage(state.language);
  }, [state.language]);

  useEffect(() => {
    controls.playbackRate(videoSetting.playbackRate)
  }, [controls, videoSetting.playbackRate])

  const [, { closeModal }] = useModal();

  const tabListRef = useRef(null);

  useOutsideAlerter(tabListRef, closeTabList);

  useEffect(() => {
    controls.play();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [src]);


  useEffect(() => {
    setState({ questionTimeList: questionList.map(item => item.time) })
  }, [questionList, setState])

  useEffect(() => {
    const index = questionTimeList.findIndex((item) => item >= state.time)
    if (index < 0) return;
    setState({ currentQuestionIndex: index });
    const timeStr = Math.round(state.time);
    if (timeStr === questionTimeList[index] && !questionPanelCloseDelaying) {
      setState({ questionPanelShowing: true });
      controls.pause();
      eventHandler({
        event: ReaderToolsEvent.ClickDragEvent
      });
      setReaderToolType(ReaderToolType.Drag)
    }

  }, [controls, questionPanelCloseDelaying, questionTimeList, setState, state.time])


  const onQuestionSeekChange = useCallback((time, index) => {
    controls.seek(time);
    setState({ currentQuestionIndex: index, questionPanelShowing: true });
    controls.pause();
  }, [controls, setState])

  const onPanelCloseHandler = useCallback(() => {
    setState({ questionPanelShowing: false, questionPanelCloseDelaying: true });
    controls.play();
    setTimeout(() => {
      setState({ questionPanelCloseDelaying: false });
    }, 1000)
  }, [controls, setState])

  const onRestartByIndex = useCallback(() => {
    const index = currentQuestionIndex - 1;
    const time = index < 0 ? 0 : questionTimeList[index];
    setState({ currentQuestionIndex: Math.max(index, 0) })
    controls.seek(time);
    onPanelCloseHandler();
  }, [controls, currentQuestionIndex, onPanelCloseHandler, questionTimeList, setState])

  const onSubtitlesPositionChangeHandler = useCallback(subtitlesPosition => {
    updateUserSettings({ videoSetting: { ...videoSetting, subtitlesPosition } });
  }, [updateUserSettings, videoSetting]);

  const onSubtitlesFontSizeChangeHandler = useCallback(subtitlesFontSize => {
    updateUserSettings({ videoSetting: { ...videoSetting, subtitlesFontSize } });
  }, [updateUserSettings, videoSetting]);

  const onPlaybackRateChangeHandler = useCallback(playbackRate => {
    updateUserSettings({ videoSetting: { ...videoSetting, playbackRate } });
    controls.playbackRate(playbackRate)
  }, [controls, updateUserSettings, videoSetting]);

  const contentView = useRef();
  const controlBar = useRef(null);
  const [panelState, setPanelState] = useState(PanelState.None);
  const panelCloseHandler = useCallback(() => {
    setPanelState(PanelState.None)
  }, [setPanelState]);
  const [
    { canvasState, canvasHeight, canvasWidth, isContentInit, svgContent, fontSize, isShowAnswer, popWindowScale, toolType },
    { canvasDispatch }
  ] = ExtendedContentEvent(contentView);

  const { activeCanvasObject, painterToolType, painterTool, fillType, canvasSVGObjectId } = canvasState;

  const eventHandler = useCallback(
    ({ event, painterMode, painterToolType, contentType }) => {
      EventBus.emit({
        eventBusType,
        event,
        payload: { painterMode, painterToolType, contentType, canvasSVGObjectId }
      });
    },
    [canvasSVGObjectId]
  );

  const setReaderToolType = type => {
    EventBus.emit({
      eventBusType,
      event: ReaderToolsEvent.SetReaderToolTypeEvent,
      payload: {
        toolType: type
      }
    });
  }

  const clickDragEvent = () => {
    eventHandler({
      event: ReaderToolsEvent.ClickDragEvent
    });
    setReaderToolType(ReaderToolType.Drag)
  }

  const clickPainterEvent = painterToolType => {
    eventHandler({
      event: ReaderToolsEvent.ClickPainterEvent,
      painterMode: PainterMode.Painting,
      painterToolType
    });
    setReaderToolType(painterToolType)
  };

  const clickEraseAllEvent = () =>
    eventHandler({ event: ReaderToolsEvent.ClickEraseAllEvent });

  const onColorChange = color => {
    EventBus.emit({
      eventBusType,
      event: PainterEvent.ChangeBrushColorEvent,
      payload: { color }
    });
  };

  const onLineWidthChange = lineWidth => {
    EventBus.emit({
      eventBusType,
      event: PainterEvent.ChangeBrushWidthEvent,
      payload: { lineWidth }
    });
  };

  const onPainterChangeHandler = useCallback(painterType => {
    EventBus.emit({
      eventBusType,
      event: PainterEvent.ChangePainterTypeEvent,
      payload: { painterType }
    });
  }, [eventBusType]);

  const onCloseHandler = useCallback(() => {
    EventBus.emit({
      eventBusType: EventBusType.Default,
      event: ReaderEvent.SetCanvasSVGObjectIdEvent,
      payload: { canvasSVGObjectId: null }
    });
    extendedContentDispatch({ type: types.IMPORT_EXTENDED_CONTENT_CANVAS_SVG, svgContent: '' });
    setReaderToolType(ReaderToolType.Drag)
    closeModal();
  }, [closeModal, setReaderToolType, extendedContentDispatch])

  useEffect(() => {
    setState({ curserState: toolType });
  }, [toolType])

  return (
    <div className={classnames(styles.videoPlayer, styles.className, styles[`cursor${toolType}`])}>
      <div className={styles.video}>
        {isContentInit && <ExtendedContentCanvas
          {...{
            canvasState,
            canvasDispatch,
            canvasWidth,
            canvasHeight
          }}
        />}
        <div ref={contentView} className={styles.svgWrap}>
          {svgContent && !canvasState.isActive && (
            <div
              className={styles.svgContent}
              dangerouslySetInnerHTML={{
                __html: getCanvasSvg(svgContent)
              }}
            />
          )}
        </div>
        {video}
        {state.language.length > 0 && (
          <Subtitles
            activeCue={activeCue}
            language={state.language}
            position={videoSetting.subtitlesPosition}
            fontSize={videoSetting.subtitlesFontSize}
          />
        )}
        {control && (
          <div ref={controlBar} className={styles.controlBar}>
            {panelState === PanelState.Pen &&
              <PainterPanel.Pen
                toolType={painterToolType}
                painterTool={painterTool[painterToolType]}
                onPanelClose={panelCloseHandler}
                onLineWidthChange={onLineWidthChange}
                onColorChange={onColorChange}
                onPainterHandler={onPainterChangeHandler}
                onShapeChange={onPainterChangeHandler}
                shapeFillTypeValue={fillType}
                isVideo={true}
              />
            }
            <ProcessBar
              className={styles.processBar}
              currentTime={state.time}
              endTime={state.duration}
              controls={controls}
              draggable
            />
            <div className={styles.left}>
              <ControlButton onClick={onCloseHandler} active={true}>
                <Icon type="text" name="timesCircle" />
              </ControlButton>
              <ControlButton
                onClick={state.isPlaying ? controls.pause : controls.play}
              >
                {state.isPlaying ? (
                  <Icon type="text" name="pause" />
                ) : (
                    <Icon type="text" name="play" />
                  )}
              </ControlButton>

              <VolumeBar
                state={state}
                controls={controls}
                onChange={val => controls.volume(val)}
                onVolumeBarActiveStateChange={state => setShowVolumeBarState(state)}
              />

              <TimeInfo currentTime={state.time} endTime={state.duration} />

              {
                !isShowVolumeBar && (
                  <>
                    <ControlButton onClick={() => controls.seek(state.time - 10)}>
                      <Icon type="text" name="backward" />
                    </ControlButton>
                    <ControlButton onClick={() => controls.seek(state.time + 10)}>
                      <Icon type="text" name="forward" />
                    </ControlButton>
                    <ControlButton onClick={() => controls.seek(0)}>
                      <Icon type="text" name="undo" />
                    </ControlButton>
                  </>
                )
              }
              <ControlButton onClick={clickDragEvent} active={curserState === 'Drag' ? true : false}>
                <Icon type="text" name="mouse" />
              </ControlButton>
              <ControlButton onClick={() => {
                clickPainterEvent(PainterToolType.Pen);
                setPanelState(PanelState.Pen);
              }} active={curserState === 'Pen' ? true : false} >
                <Icon type="text" name="pencilAlt" />
              </ControlButton>
              <ControlButton onClick={clickEraseAllEvent}>
                <Icon type="text" name="trashAlt" />
              </ControlButton>
            </div>
            <div className={styles.right}>
              {subtitles.length > 0 && (
                <SubtitleControl
                  value={state.language}
                  subtitles={subtitles}
                  onChange={controls.setSubtitlesLanguage}
                />
              )}
              <SettingPanelControl
                subtitlesFontSizeValue={videoSetting.subtitlesFontSize}
                onSubtitlesFontSizeChange={onSubtitlesFontSizeChangeHandler}
                playbackRateValue={videoSetting.playbackRate}
                onPlaybackRateChange={onPlaybackRateChangeHandler}
                isShowSubtitleSetting={subtitles.length > 0}
                subtitlesPositionValue={videoSetting.subtitlesPosition}
                onSubtitlesPositionChange={onSubtitlesPositionChangeHandler}
              />
              {
                (seekList.length > 0 || questionList.length > 0) &&
                <ControlButton onClick={() => toggleTabList()} active={isShowTabList}>
                  <Icon type="text" name="list" />
                </ControlButton>
              }
              {
                questionList.length === 0 && (
                  <ControlButton onClick={controls.fullscreen}>
                    <Icon type="text" name="expand" />
                  </ControlButton>
                )
              }
              <ControlButton onClick={onCloseHandler} active={true}>
                <Icon type="text" name="timesCircle" />
              </ControlButton>
            </div>
          </div>
        )}
      </div>
      <TabList
        ref={tabListRef}
        seekList={seekList}
        questionList={questionList}
        onSeekChange={controls.seek}
        onQuestionSeekChange={(time, index) => onQuestionSeekChange(time, index)}
        showing={isShowTabList}
      />
      {
        (questionList.length > 0 && questionList[currentQuestionIndex]) && (
          <QuestionPanel
            showing={questionPanelShowing}
            data={questionList[currentQuestionIndex]}
            onClose={() => onPanelCloseHandler()}
            onRestartByIndex={onRestartByIndex}
          />
        )
      }
    </div>
  );
};

export default VideoPlayer;
