import React, { useEffect } from 'react';
import path from 'path';
import { useStore, StoreTypes } from 'context';
import useSetState from 'customHooks/setState';
import toolsEventMap from 'components/ReaderTools/toolsEventMap';
import { BookFlipType } from 'constants/flipTypes';
import { withTranslation } from 'react-i18next';
import classnames from 'classnames';
import { groupPages } from 'util/book';
import Icon from 'components/Icon';
import styles from './index.module.scss';

const ReaderToolPageSearcher = ({ t, type, clickHandlerParameters: [book, LRFlip] = [] }) => {
    const [{ books, bookId, catalog }] = useStore(StoreTypes.books);
    const [{ pageIndex, isDoublePageMode }] = useStore(StoreTypes.reader);
    const [{
        pageData,
        pageNumber }, setState] = useSetState({
            pageData: {},
            pageNumber: ''
        });
    book = books.find(item => item.bookId === bookId);

    useEffect(() => {
        if (!book) {
            return;
        }

        if (catalog.length === 0) return;

        const { pageInfos } = book;
        const pages = pageInfos.reduce((acc, item) => {
            acc[item.pageIndex] = path.basename(item.html, path.extname(item.html));
            return acc;
        }, {});
        const pageList = getLabel(groupPages(pages, isDoublePageMode));
        setState({ pageData: pageList });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [book, bookId, isDoublePageMode, pageIndex, catalog]);

    // ReaderTool頁數顯示
    useEffect(() => {
        const isExist = Object.keys(pageData).length > 0;
        if (!isExist) return;

        const newPage = pageData[pageIndex];
        if (!newPage) return;

        let pageContent = '';
        if (isDoublePageMode && newPage.length === 2) {
            if (newPage[0].label === newPage[1].label) {
                pageContent = newPage[0].label;
            } else {
                pageContent = LRFlip === BookFlipType.LEFT_TO_RIGHT ? 
                    `${newPage[0].label}-${newPage[1].label}` :
                    `${newPage[1].label}-${newPage[0].label}`;
            }
        } else {
            pageContent = newPage[0].label;
        }
        setState({ pageNumber: pageContent });
    }, [LRFlip, book, isDoublePageMode, pageData, pageIndex, setState]);

    const getLabel = (data) => {
        return Object.values(data).map((pages, index) => {
            const labels = []
            for (let page of pages) {
                const label = (catalog.getLabel(page - 1)).toString()
                labels.push({ label, page, index })
            }
            return labels;
        })
    };

    return (
        <>
            <div className={styles.pageSearcher} key={type}>
                <div className={classnames(styles.button, styles.prev)}
                    onClick={() => toolsEventMap[type].clickLeftHandler(book, LRFlip)}>
                    <Icon name="CaretLeft" />
                </div>
                <div className={styles.pageInfo}
                    onClick={() => toolsEventMap[type].clickHandler()}>{pageNumber}</div>
                <div className={classnames(styles.button, styles.next)}
                    onClick={() => toolsEventMap[type].clickRightHandler(book, LRFlip)}>
                    <Icon name="CaretRight" />
                </div>
            </div>
            {/* <div className={styles.text}>{t(toolsEventMap[type].title)}</div> */}
        </>
    );
};

export default withTranslation()(ReaderToolPageSearcher);
