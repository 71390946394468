import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import classnames from 'classnames';
import styles from './index.module.scss';
import { EventBus } from 'events/EventBus';
import { ReaderEvent } from 'events/EventTypes';
import { ModalComponentType } from 'components/common/Modal/ModalComponentGenerator';

export const CanvasSVGObjectView = ({ annotationId, viewIndex, svgString, svgShow }) => {
    const canvasSVGRef = useRef();
    const { userAgent } = navigator;

    useEffect(() => {
        const node = ReactDOM.findDOMNode(canvasSVGRef.current);
        if (!node) return;
        const groups = node.querySelectorAll('g');
        groups.forEach(group => {
            if (group.id) {
                if (group.id === ModalComponentType.StickyNote || group.id === ModalComponentType.Whiteboard || group.id === ModalComponentType.Link) {
                    group.style.cursor = `pointer`;
                    group.style.pointerEvents = `auto`;
                }
                const handler = (event) => {
                    EventBus.emit({ event: ReaderEvent.ClickCanvasSVGObjectEvent, payload: { annotationId, id: group.id, target: event.target } });
                };

                // if (/Android/i.test(userAgent) || /iPhone|iPad|iPod/i.test(userAgent)) {
                //     group.ontouchend = handler;
                // } else {
                    group.onpointerup = handler;
                // }
            }
        });
    }, [annotationId, userAgent, viewIndex]);

    return (
        <div
            className={classnames(styles.svgContent, styles.canvasSvgContent)}
            ref={canvasSVGRef}
            dangerouslySetInnerHTML={{ __html: svgString }}
        />
    );
};
