export const IExtendedResourceRepository = {
  getVideoSrc: ({ bookId, filename }) => {
    console.log(`getVideoSrc is not implemented`);
  },
  getAudioSrc: ({ bookId, filename }) => {
    console.log(`getAudioSrc is not implemented`);
  },
  getSubtitleSrc: ({ bookId, filename }) => {
    console.log(`getSubtitleSrc is not implemented`);
  },
  getInternalHtmlSrc: ({ bookId, filename }) => {
    console.log(`getInternalHtmlSrc is not implemented`);
  },
  getDataSrc: ({ bookId, pathName }) => {
    console.log(`getDataSrc is not implemented`);
  },
  getInternalWindowHtml: async ({ bookId, filename }) => {
    console.log(`getInternalWindowHtml is not implemented`);
  },
  getJSONContent: async ({ bookId, pathName }) => {
    console.log(`getJSONContent is not implemented`);
  },
  getMathJaxPath: () => {
    console.log(`getMathJaxPath is not implemented`);
  },
  getBookmarks: async (bookId) => {
    console.log(`getBookMarks is not implemented`);
  },
  getPageTools: async ({ bookId }) => {
    console.log(`getPageTools is not implemented`);
  },
  getToolbarTools: async ({ bookId }) => {
    console.log(`getToolbarTools is not implemented`);
  },
  postTools: ({ bookId, filename }) => {
    console.log(`postTools is not implemented`);
  },
  getWordGridAnchorDirection: () => {
    console.log(`getWordGridAnchorDirection is not implemented`);
  },
};
