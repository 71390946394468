import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useStore, StoreTypes } from 'context';
import * as types from 'constants/actionTypes';
import styles from './index.module.scss';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { useFetchBooks } from 'customHooks/book';
import { ReaderEvent } from 'events/EventTypes';
import { EventBus } from 'events/EventBus';
import { Roles } from 'constants/role';
import DialogModule from 'components/Dialogs/'
import { AnnotationActivityTab } from 'components/AnnotationActivityTab/';
import { AnnotationType } from 'constants/annotationTypes';
import Repository from 'repositories/Repository';
import Card from './Card';
import classnames from 'classnames';
import Pagination from 'components/Pagination';
import Autocomplete from 'components/Autocomplete';
import useSetState from 'customHooks/setState';
import { convertArrayToMap, groupArray } from 'util/array';

import {
    useUpdateDisplayLanguage,
    useUpdateUserSettings,
    useUpdateUserFavorites,
    useDeleteUserFavorites
} from 'customHooks/userSetting';
import { UserSettingsAPI } from 'api/UserSettingsAPI';
import { usePreparation } from 'customHooks/preparation';

import LinearProgress from '@material-ui/core/LinearProgress';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import Header from 'components/Header';
import Footer from 'components/Footer';
import LoginPopup from 'components/Login/Popup';
import { scrollTo } from 'util/scrollTo';
import { educationalSystemTable, gradeTable, subjectTable } from 'constants/optionTable';
import Loading from 'components/Loading';

const TabAnnotationType = [
    AnnotationType.CLASS_PREPARATION,
    AnnotationType.ACTIVITY
];

export const BookshelfWeb = () => {
    const [{ progress }, readerDispatch] = useStore(StoreTypes.reader);
    const [, bookDispatch] = useStore(StoreTypes.books);
    const [{ role, token, isLogin }] = useStore(StoreTypes.user);
    const [{ annotationId }, annotationDispatch] = useStore(StoreTypes.annotation);
    const [selectBookID, setSelectBookID] = useState("");
    const { getShelfBook, getBookTags, getPurchasedProducts } = useFetchBooks();
    const [inputDialogOpen, setInputDialogOpen] = useState(false);
    const [inputText, setInputText] = useState("");
    const [tab, setTab] = React.useState(0);
    const [isAnnotationTabOpen, setAnnotationTabOpen] = useState(false);
    const [progressOpen, setProgressOpen] = useState(false);
    const [loadingOpen, setLoadingOpen] = useState(false);
    const [progressText, setProgressText] = useState("讀取頁面中...");
    const [loadingText, setLoadingText] = useState("讀取備課清單中...");
    const router = useStore(StoreTypes.router);
    const { BookContentRepository } = Repository;
    const { updateDisplayLanguage } = useUpdateDisplayLanguage();
    const updateUserSettings = useUpdateUserSettings();
    const updateUserFavorites = useUpdateUserFavorites();
    const deleteUserFavorites = useDeleteUserFavorites();
    const { updatePreparationAnnotation } = usePreparation();
    const [bookTags, setBookTags] = useState({});
    const [{ books }] = useStore(StoreTypes.settings);
    const root = document.getElementById('root');
    const [{ SchoolYear, EducationalSystem, Subject, Grade }, setTag] = useSetState({
        SchoolYear: [],
        EducationalSystem: [],
        Subject: [],
        Grade: []
    });
    const [currentShelfList, setCurrentShelfList] = useState({
        lists: [],
        total: 0,
        currentIndex: null,
        isLoading: true
    });
    const [deleteShelfDialog, setShelfDialog] = useState({
        bookId: null,
        status: false,
        id: null
    });
    // tag 初始選項
    const [initTag, setInitTag] = useState({});
    // 篩選 tag
    const [filterTag, setFilterTag] = useState([]);
    // 篩選 tag 順序
    const [filterOrderTag, setFilterOrderTag] = useState([]);

    useEffect(() => {
        if (!isLogin) return;

        (async () => {
            const userSettings = await UserSettingsAPI.getUserSettings(token);
            const favorites = await UserSettingsAPI.getUserFavorites(token);

            if (userSettings.status === 'success') {
                await updateUserSettings(userSettings.data, false);
            }

            if (favorites.status === 'success') {
                if (!favorites.data.isUpdateOldBook) {
                    const oldData = await getOldData();
                    const oldCollectedBook = {};
                    const currentTime = Date.now();
                    Object.values(oldData).forEach(item => {
                        oldCollectedBook[item.id] = {
                            collectedTime: currentTime,
                            bookId: item.bookId,
                            id: item.id
                        }
                    })

                    await updateUserFavorites({
                        books: { ...oldCollectedBook, ...favorites.data.books },
                        isUpdateOldBook: true
                    });
                } else {
                    await updateUserFavorites(favorites.data, false);
                }
            }
            updateDisplayLanguage();
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLogin, updateDisplayLanguage, updateUserFavorites]);

    useEffect(() => {
        readerDispatch({ type: types.SET_BOOK_PAGE_INDEX, pageIndex: 0 });
        annotationDispatch({ type: types.RESET_ANNOTATION_INFO });
    }, [annotationDispatch, readerDispatch]);

    useEffect(() => {
        (async () => {
            const tags = await getBookTags();
            const dataMap = {
                Grade: tags.filter(tag => tag.category === 'Grade'),
                Subject: tags.filter(tag => tag.category === 'Subject'),
                EducationalSystem: tags.filter(tag => tag.category === 'EducationalSystem'),
                SchoolYear: tags.filter(tag => tag.category === 'SchoolYear'),
            }

            setInitTag(dataMap);
            setBookTags(dataMap);
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const goBook = (bookId, interactiveObjectId) => {
        router.history.push({ pathname: `/${bookId}`, search: interactiveObjectId ? `?interactiveObjectId=${interactiveObjectId}` : '' });
    };
    const openBook = ({ bookId, interactiveObjectId }) => {
        setSelectBookID(bookId);
        bookDispatch({ type: types.SET_BOOK_ID, payload: bookId });
        if (role === Roles.EDITOR) {
            annotationDispatch({
                type: types.UPDATE_ANNOTATION_INFO,
                annotationId: interactiveObjectId,
                annotationType: AnnotationType.INTERACTIVE_OBJECT
            });

            readerDispatch({
                type: types.SET_FULL_WIDTH_INFO,
                fullWidthInfo: { mode: false }
            });
            goBook(bookId, interactiveObjectId);
            // } else if (role === Roles.GUEST) {
            //     EventBus.emit({
            //         event: ReaderEvent.CreateAndEnterAnnotationEvent,
            //         payload: {
            //             bookId,
            //             annotationType: AnnotationType.GUEST,
            //             name: "未命名",
            //             callback: (err) => {
            //                 if (!err) {
            //                     goBook(bookId);
            //                 }
            //             }
            //         }
            //     });
        } else {
            setAnnotationTabOpen(true);
        }
    };

    const getAnnotationType = ({ role, tab }) => {
        switch (role) {
            case Roles.TEACHER:
            case Roles.STUDENT:
            case Roles.PARENT:
                return TabAnnotationType[tab]
            default:
                return AnnotationType.GUEST
        }
    }

    const confirmHandler = (annotationId) => async () => {
        if (annotationId) {
            setProgressOpen(true)
            await updatePreparationAnnotation({ id: annotationId, token })
            EventBus.emit({ event: ReaderEvent.SetProgressEvent, payload: { progress: 100 } })
        }
        setProgressText("讀取完成")
        setTimeout(() => {
            setProgressOpen(false)
            EventBus.emit({
                event: ReaderEvent.CreateAndEnterAnnotationEvent,
                payload: {
                    bookId: selectBookID,
                    annotationType: getAnnotationType({ role, tab }),
                    annotationId,
                    name: (inputText === "") ? "未命名" : inputText,
                    callback: (err) => {
                        if (err) {
                            setState(prev => {
                                return {
                                    ...prev,
                                    open: true
                                }
                            });
                        } else {
                            goBook(selectBookID);
                        }
                    }
                }
            });
        }, 1000);

    };

    //備課與活動選單
    const TabDialogContent = <AnnotationActivityTab id="alert-dialog-description" setLoadingOpen={setLoadingOpen} setAnnotationTabOpen={setAnnotationTabOpen} tab={tab} setTab={setTab} TabAnnotationType={role === Roles.TEACHER ? TabAnnotationType : [AnnotationType.GUEST]} setInputText={setInputText} />;
    const TabDialogAction = <div>
        <Button onClick={() => {
            setInputDialogOpen(true)
        }} color="primary"
            style={{
                border: "solid",
                color: "blue",
                marginRight: 5,
                borderWidth: 2
            }}
        >
            新增
        </Button>
        <Button
            style={{
                border: "solid",
                color: "blue",
                borderWidth: 2
            }}
            onClick={confirmHandler(annotationId)} color="primary" autoFocus
        >
            確定
        </Button>
    </div>;

    //輸入名稱彈窗
    const InputDialogContent = <TextField
        id="outlined-name"
        label="輸入名稱"
        value={inputText}
        onChange={(e) => {
            setInputText(e.target.value)
        }}
        margin="normal"
        variant="outlined"
    />
    const InputDialogAction = (
        <Button
            className={styles.dialogButton}
            onClick={confirmHandler(null)}
            color="primary"
            autoFocus
            style={{
                border: "solid",
                color: "blue",
                borderWidth: 2
            }}
        >
            確定
        </Button>
    )

    const ProgressContent = <div className={styles.progressContent}>
        <div className={styles.text}>{progressText}</div>
        <div className={styles.progress}><LinearProgress variant="determinate" value={progress || 0} /></div>
    </div>

    const LoadingContent = <div className={styles.loadingContent}>
        <div className={styles.text}>{loadingText}</div>
        <div className={styles.loading}><CircularProgress disableShrink /></div>
    </div>

    const [state, setState] = React.useState({
        open: false,
        vertical: 'bottom',
        horizontal: 'center',
    });

    const { vertical, horizontal, open } = state;

    function handleClose() {
        setState({ ...state, open: false });
    }

    const changePage = (event, page) => {
        const tagData = [...SchoolYear, ...EducationalSystem, ...Subject, ...Grade];
        fetchBook(books, tagData, page);
    }

    const pageCount = useMemo(() => {
        if (currentShelfList.total > 0) {
            return Math.ceil(currentShelfList.total / 10)
        }
        return 0
    }, [currentShelfList])

    const filterChange = (event, value, key) => {
        let tagArr = [];
        const optionObj = value;
        const category = key;

        if (optionObj === null) {
            const deleteOrderTagIndex = filterOrderTag.findIndex(item => {
                return item.category === category
            });

            if (deleteOrderTagIndex === -1) {
                setTag({ [category]: tagArr });
                return
            };

            const updateOrderArr = [...filterOrderTag];

            updateOrderArr.splice(deleteOrderTagIndex, 1);
            setFilterOrderTag([...updateOrderArr]);

        } else {
            const isSkip = (optionObj.category !== 'SchoolYear' && optionObj.label !== '低年級' && optionObj.label !== '中年級' && optionObj.label !== '高年級');
            tagArr.push(optionObj);

            if (filterOrderTag.length > 0 && isSkip) {
                const someIndex = filterOrderTag.findIndex(item => optionObj.category === item.category);
                if (someIndex !== -1) {
                    const updateOrderArr = [...filterOrderTag];
                    updateOrderArr[someIndex] = optionObj
                    setFilterOrderTag([...updateOrderArr]);
                } else {
                    setFilterOrderTag([...filterOrderTag, ...tagArr]);
                }
            } else if (isSkip) {
                setFilterOrderTag([...filterOrderTag, ...tagArr]);
            }
        }

        setTag({ [category]: tagArr });
    };

    // 篩選 options
    const filterCategoryOptions = (tableType, options) => {
        const baseType = ['EducationalSystem', 'Grade', 'Subject'];
        let tagObj = {};
        let levelTwoCategory = '';
        let levelTwoOption = '';
        let levelThreeCategory = '';
        let table = {};
        const isMultiple = options.length > 1;
        const levelOneOption = options.length > 0 ? options[0].label : '';

        if (isMultiple) {
            levelTwoCategory = options[1].category;
            levelTwoOption = options[1].label;
            levelThreeCategory = baseType.filter(item => { return item !== tableType && item !== levelTwoCategory });
        }

        switch (tableType) {
            case 'EducationalSystem':
                tagObj['Grade'] = getOptionsData('Grade', Object.keys(educationalSystemTable[levelOneOption].Grade));
                tagObj['Subject'] = getOptionsData('Subject', Object.keys(educationalSystemTable[levelOneOption].Subject));
                table = educationalSystemTable;
                break;
            case 'Grade':

                tagObj['EducationalSystem'] = getOptionsData('EducationalSystem', Object.keys(gradeTable[levelOneOption].EducationalSystem))
                tagObj['Subject'] = getOptionsData('Subject', Object.keys(gradeTable[levelOneOption].Subject))

                table = gradeTable;
                break;
            case 'Subject':

                tagObj['EducationalSystem'] = getOptionsData('EducationalSystem', Object.keys(subjectTable[levelOneOption].EducationalSystem))
                tagObj['Grade'] = getOptionsData('Grade', Object.keys(subjectTable[levelOneOption].Grade))

                table = subjectTable;
                break;
            default:
                tagObj = { ...initTag };
                break;
        }

        if (isMultiple) {
            tagObj[levelTwoCategory] = getOptionsData(levelTwoCategory, Object.keys(table[levelOneOption][levelTwoCategory]));
            tagObj[levelThreeCategory] = getOptionsData(levelThreeCategory, table[levelOneOption][levelTwoCategory][levelTwoOption]);
        }
        setBookTags({ 'SchoolYear': initTag.SchoolYear, ...tagObj });
    };

    // 取得 option 資訊
    const getOptionsData = (key, optionArr) => {
        const newOptions = initTag[key].filter(item => {
            return optionArr.find(option => {
                return option === item.label
            });
        });
        return newOptions;
    };

    useEffect(() => {
        setFilterTag([...SchoolYear, ...EducationalSystem, ...Subject, ...Grade]);
    }, [SchoolYear, EducationalSystem, Subject, Grade]);

    const fetchBook = useCallback((booksData, tagData, page = 1) => {
        const currentData = {
            lists: [],
            total: 0,
            currentIndex: page - 1,
            isLoading: false
        };

        if (Object.keys(booksData).length === 0) {
            setCurrentShelfList(currentData);
            return
        }

        const booksDataValue = Object.values(booksData);
        const bookIds = [];

        booksDataValue.forEach(item => {
            bookIds.push(item.bookId);
        });
        // 撈資料
        const fetchAllData = async () => {
            return await getShelfBook({ token, bookIds });
        };

        fetchAllData().then((res) => {
            if (res.length === 0) {
                setCurrentShelfList(currentData);
                return
            }
            return res
        }).then(res => {
            if (!res) return
            const allData = res;

            // 判斷篩選
            let filterBook = [];
            if (tagData.length > 0) {
                const newTagData = tagData.map((item) => {
                    return item.tagId
                })

                const filterHasTagsKey = allData.filter((item) => {
                    return item.tags && item.tags.length !== 0
                })

                let filterData = [];

                filterData = filterHasTagsKey.filter((item) => {
                    return item.tags.indexOf(newTagData[0]) > -1
                }).filter(arr => {
                    if (newTagData[1]) {
                        return arr.tags.indexOf(newTagData[1]) > -1
                    } else {
                        return arr
                    }
                }).filter(arr => {
                    if (newTagData[2]) {
                        return arr.tags.indexOf(newTagData[2]) > -1
                    } else {
                        return arr
                    }
                }).filter(arr => {
                    if (newTagData[3]) {
                        return arr.tags.indexOf(newTagData[3]) > -1
                    } else {
                        return arr
                    }
                })
                filterBook = filterData;
            } else {
                filterBook = allData;
            }

            // 分組
            if (filterBook.length > 0) {
                const idObj = convertArrayToMap(Object.values(books), 'bookId');
                const sortBook = filterBook.map(item => {
                    return { ...item, ...idObj[item.bookId] }
                }).sort((a, b) => {
                    return b.collectedTime - a.collectedTime
                });

                const groupBooks = groupArray(sortBook, 10);

                if (!groupBooks[currentData.currentIndex]) {
                    currentData.currentIndex = currentData.currentIndex - 1;
                }

                const currentPageBooks = groupBooks[currentData.currentIndex];

                currentData.total = sortBook.length;
                currentData.lists = currentPageBooks.map(item => {
                    return { ...item, ...idObj[item.bookId] }
                });
                currentData.isLoading = false
            }
            setCurrentShelfList({ ...currentShelfList, ...currentData });
            scrollTo(root, 0, 300);
        })
    }, [books, currentShelfList, getShelfBook, root, token])

    // 更新舊資料
    const getOldData = async () => {
        const fetchOldData = await getPurchasedProducts({ token });
        const filterOldData = fetchOldData.map(item => {
            return {
                id: item.id,
                bookId: item.bookId
            }
        });
        const formatOldData = convertArrayToMap(filterOldData, 'id');

        return formatOldData;
    };

    useEffect(() => {
        if (!token || !books) return;

        const tagData = [...SchoolYear, ...EducationalSystem, ...Subject, ...Grade];
        fetchBook(books, tagData, currentShelfList.currentIndex + 1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [books, SchoolYear, EducationalSystem, Subject, Grade, token]);

    const deleteBook = (id) => {
        const tmpData = { ...books };
        delete tmpData[id];

        deleteUserFavorites({ books: { ...tmpData } });

        setShelfDialog({
            bookId: null,
            status: false,
            id: null
        });
    }

    const deleteShelfDialogAction = <div>
        <Button
            style={{
                border: "solid",
                color: "blue",
                borderWidth: 2,
                marginRight: 5,
            }}
            onClick={() => {
                setShelfDialog({
                    bookId: null,
                    status: false,
                    id: null
                })
            }}
            color="primary"
        >取消</Button>
        <Button
            style={{
                border: "solid",
                color: "blue",
                borderWidth: 2
            }}
            onClick={() => {
                deleteBook(deleteShelfDialog.id)
            }}
            color="primary"
        >確定</Button>
    </div>;

    // 刪除書櫃提示
    const alertShelfDialog = (bookId, id) => {
        setShelfDialog({
            bookId: bookId,
            status: true,
            id: id
        });
    }

    useEffect(() => {
        const isFilter = filterOrderTag.length > 0;

        if (!isFilter) {
            filterCategoryOptions('init', filterOrderTag);
        } else {
            let tableCategory = filterOrderTag[0].category;
            filterCategoryOptions(tableCategory, filterOrderTag);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterOrderTag]);

    return (
        <>
            <Header path={'我的書櫃'} />
            <LoginPopup />
            <div className={styles.index}>
                <div className={styles.container}>
                    {!token ? null : <div className={styles.filter}>
                        <Autocomplete title={'學年度'} optionsData={bookTags.SchoolYear} onChange={(event, value) => filterChange(event, value, 'SchoolYear')} />
                        <Autocomplete title={'學制'} optionsData={bookTags.EducationalSystem} onChange={(event, value) => filterChange(event, value, 'EducationalSystem')} />
                        <Autocomplete title={'年級'} optionsData={bookTags.Grade} onChange={(event, value) => filterChange(event, value, 'Grade')} />
                        <Autocomplete title={'科目'} optionsData={bookTags.Subject} onChange={(event, value) => filterChange(event, value, 'Subject')} />
                    </div>}

                    {isLogin ? (
                        <>
                            {
                                currentShelfList.isLoading ? <Loading /> : (
                                    <>
                                        {
                                            isLogin && currentShelfList.lists.length > 0 &&
                                            <ul className={styles['list-container']}>
                                                {currentShelfList.lists.map(book =>
                                                    <Card book={book} BookContentRepository={BookContentRepository} openBook={openBook} deleteBook={deleteBook} alertShelfDialog={alertShelfDialog} key={book.bookId} />
                                                )}
                                            </ul>
                                        }
                                        {currentShelfList.lists.length === 0 && filterTag.length !== 0 && token && <div className={styles.noResult}>找不到符合搜尋條件的書本</div>}
                                        {currentShelfList.lists.length === 0 && filterTag.length === 0 && token && (
                                            <div className={styles.default}>
                                                <div className={styles.title}>目前尚未有任何書本</div>
                                                <div className={styles.sub_title}>請前往書城加入您要的書本</div>
                                                <div className={styles.go_box}>
                                                    <a href="/bookstore" rel="noopener noreferrer" className={styles.go_onemall}>前往書城</a>
                                                </div>
                                            </div>
                                        )}
                                    </>
                                )
                            }
                        </>
                    ) : (
                            <div className={styles.default}>
                                <div className={styles.sub_title}>請先登入</div>
                            </div>
                        )
                    }
                    <DialogModule
                        open={deleteShelfDialog.status}
                        content={'您確定要刪除書本嗎？刪除後，會將書本從您的書櫃中移除。'}
                        style={{ paddingBottom: '20px' }}
                        action={deleteShelfDialogAction}
                    />

                    {isLogin && currentShelfList.lists.length > 0 ? <Pagination count={pageCount} page={currentShelfList.currentIndex + 1} onChange={changePage}></Pagination> : null}

                    {
                        (role === Roles.TEACHER || role === Roles.GUEST || role === Roles.STUDENT || role === Roles.PARENT) && (<div>
                            <DialogModule
                                open={isAnnotationTabOpen}
                                setOpen={setAnnotationTabOpen}
                                content={TabDialogContent}
                                action={TabDialogAction}
                            />
                            <DialogModule
                                open={inputDialogOpen}
                                setOpen={setInputDialogOpen}
                                content={InputDialogContent}
                                action={InputDialogAction}
                            />
                            <DialogModule
                                open={progressOpen}
                                content={ProgressContent}
                            />
                            <DialogModule
                                open={loadingOpen}
                                content={LoadingContent}
                            />
                        </div>)
                    }

                    <Snackbar
                        anchorOrigin={{ vertical, horizontal }}
                        key={`${vertical},${horizontal}`}
                        open={open}
                        onClose={handleClose}
                    >
                        <Button variant="contained" style={{ backgroundColor: "#f50057", color: "#ccc", width: 200, height: 70, fontSize: 32 }} >
                            請登入
                    </Button>
                    </Snackbar>
                </div>
            </div>
            <Footer />
        </>
    );
};


export const ElectronBookShlef = () => {
    const [{ progress }, readerDispatch] = useStore(StoreTypes.reader);
    const [{ books }, bookDispatch] = useStore(StoreTypes.books);
    const [{ role, token }] = useStore(StoreTypes.user);
    const [{ annotationId, annotationType }, annotationDispatch] = useStore(StoreTypes.annotation);
    const [selectBookID, setSelectBookID] = useState("");
    const { getPurchasedProducts } = useFetchBooks();
    const [inputDialogOpen, setInputDialogOpen] = useState(false);
    const [inputText, setInputText] = useState("");
    const [tab, setTab] = React.useState(0);
    const [isAnnotationTabOpen, setAnnotationTabOpen] = useState(false);
    const [progressOpen, setProgressOpen] = useState(false);
    const [loadingOpen, setLoadingOpen] = useState(false);
    const [progressText, setProgressText] = useState("讀取頁面中...");
    const [loadingText, setLoadingText] = useState("讀取備課清單中...");
    const router = useStore(StoreTypes.router);
    const { BookContentRepository } = Repository;
    const { updateDisplayLanguage } = useUpdateDisplayLanguage();
    const updateUserSettings = useUpdateUserSettings();
    const { updatePreparationAnnotation, getPreparationList } = usePreparation();

    useEffect(() => {
        (async () => {
            if (token) {
                const res = await UserSettingsAPI.getUserSettings(token);
                if (res.status === 'success') {
                    await updateUserSettings(res.data, false);
                }
            }
            updateDisplayLanguage();
        })();
    }, [token, updateDisplayLanguage, updateUserSettings]);

    useEffect(() => {
        readerDispatch({ type: types.SET_BOOK_PAGE_INDEX, pageIndex: 0 });
        annotationDispatch({ type: types.RESET_ANNOTATION_INFO });
    }, [annotationDispatch, readerDispatch]);

    useEffect(() => {
        (async () => {
            const books = await getPurchasedProducts({ token });
            if (books.length === 0) {
                // TODO: Request User Login
            }
        })();
    }, [annotationDispatch, getPurchasedProducts, token]);

    const goBook = (bookId, interactiveObjectId) => {
        router.history.push({ pathname: `/${bookId}`, search: interactiveObjectId ? `?interactiveObjectId=${interactiveObjectId}` : '' });
    };
    const openBook = ({ bookId, interactiveObjectId }) => {
        setSelectBookID(bookId);
        bookDispatch({ type: types.SET_BOOK_ID, payload: bookId });
        if (role === Roles.EDITOR) {
            annotationDispatch({
                type: types.UPDATE_ANNOTATION_INFO,
                annotationId: interactiveObjectId,
                annotationType: AnnotationType.INTERACTIVE_OBJECT
            });

            readerDispatch({
                type: types.SET_FULL_WIDTH_INFO,
                fullWidthInfo: { mode: false }
            });
            goBook(bookId, interactiveObjectId);
            // } else if (role === Roles.GUEST) {
            //     EventBus.emit({
            //         event: ReaderEvent.CreateAndEnterAnnotationEvent,
            //         payload: {
            //             bookId,
            //             annotationType: AnnotationType.GUEST,
            //             name: "未命名",
            //             callback: (err) => {
            //                 if (!err) {
            //                     goBook(bookId);
            //                 }
            //             }
            //         }
            //     });
        } else {
            setAnnotationTabOpen(true);
        }
    };

    const getAnnotationType = ({ role, tab }) => {
        switch (role) {
            case Roles.TEACHER:
            case Roles.STUDENT:
            case Roles.PARENT:
                return TabAnnotationType[tab]
            default:
                return AnnotationType.GUEST
        }
    }

    const confirmHandler = (annotationId, bookId) => async () => {
        if (annotationId) {
            setProgressOpen(true)
            await updatePreparationAnnotation({ id: annotationId, token })
            EventBus.emit({ event: ReaderEvent.SetProgressEvent, payload: { progress: 100 } })
        }
        setProgressText("讀取完成")
        setTimeout(() => {
            setProgressOpen(false)
            EventBus.emit({
                event: ReaderEvent.CreateAndEnterAnnotationEvent,
                payload: {
                    bookId: bookId || selectBookID,
                    annotationType: getAnnotationType({ role, tab }),
                    annotationId,
                    name: (inputText === "") ? "未命名" : inputText,
                    callback: (err) => {
                        if (err) {
                            setState(prev => {
                                return {
                                    ...prev,
                                    open: true
                                }
                            });
                        } else {
                            goBook(bookId || selectBookID);
                        }
                    }
                }
            });
        }, 1000);
    };

    //快速進入最新開過的課程
    const renewBook = async bookId => {
        if (~TabAnnotationType.indexOf(annotationType)) {
            setTab(TabAnnotationType.indexOf(annotationType));
        }
        bookDispatch({ type: types.SET_BOOK_ID, payload: bookId });
        const results = await getPreparationList(bookId, token, role === Roles.TEACHER ? AnnotationType.CLASS_PREPARATION : AnnotationType.GUEST);
        results.sort((a, b) => {
            return b.updatedAt - a.updatedAt;
        })
        let annotations = results.reduce((acc, v) => {
            if (!v.isDeleted) {
                if (Array.isArray(acc[v.type])) {
                    acc[v.type].push(v);
                } else {
                    acc[v.type] = [v];
                }
            }
            return acc;
        }, {});
        if (!annotations[AnnotationType.GUEST]) {
            createNewCourse(bookId);
            return;
        }
        const annotationId = annotations[AnnotationType.GUEST][0].id;
        annotationDispatch({ type: types.UPDATE_ANNOTATION_INFO, annotationId });
        confirmHandler(annotationId, bookId)();
    };

    //直接上課
    const createNewCourse = bookId => {
        if (~TabAnnotationType.indexOf(annotationType)) {
            setTab(TabAnnotationType.indexOf(annotationType));
        }
        bookDispatch({ type: types.SET_BOOK_ID, payload: bookId });
        confirmHandler(null, bookId)();
    }


    //備課與活動選單
    const TabDialogContent = <AnnotationActivityTab id="alert-dialog-description" setLoadingOpen={setLoadingOpen} setAnnotationTabOpen={setAnnotationTabOpen} tab={tab} setTab={setTab} TabAnnotationType={role === Roles.TEACHER ? TabAnnotationType : [AnnotationType.GUEST]} setInputText={setInputText} />;
    const TabDialogAction = <div>
        <Button onClick={() => {
            setInputDialogOpen(true)
        }} color="primary"
            style={{
                border: "solid",
                color: "blue",
                marginRight: 5,
                borderWidth: 2
            }}
        >
            新增
        </Button>
        <Button
            style={{
                border: "solid",
                color: "blue",
                borderWidth: 2
            }}
            onClick={confirmHandler(annotationId)} color="primary" autoFocus
        >
            確定
        </Button>
    </div>;

    //輸入名稱彈窗
    const InputDialogContent = <TextField
        id="outlined-name"
        label="輸入名稱"
        value={inputText}
        onChange={(e) => {
            setInputText(e.target.value)
        }}
        margin="normal"
        variant="outlined"
    />
    const InputDialogAction = (
        <Button
            className={styles.dialogButton}
            onClick={confirmHandler(null)}
            color="primary"
            autoFocus
            style={{
                border: "solid",
                color: "blue",
                borderWidth: 2
            }}
        >
            確定
        </Button>
    )

    const ProgressContent = <div className={styles.progressContent}>
        <div className={styles.text}>{progressText}</div>
        <div className={styles.progress}><LinearProgress variant="determinate" value={progress || 0} /></div>
    </div>

    const LoadingContent = <div className={styles.loadingContent}>
        <div className={styles.text}>{loadingText}</div>
        <div className={styles.loading}><CircularProgress disableShrink /></div>
    </div>

    const [state, setState] = React.useState({
        open: false,
        vertical: 'bottom',
        horizontal: 'center',
    });

    const { vertical, horizontal, open } = state;

    function handleClose() {
        setState({ ...state, open: false });
    }

    return (
        <>
            <Header />
            <LoginPopup />
            <div className={styles.index}>
                <div className={styles.container}>
                    <ul className={styles['list-container']}>
                        {books.length > 0 ? books.map(book =>
                            (
                                <li className={styles['list-item']}>
                                    <div className={styles['list-main']} key={book.id} onClick={() => createNewCourse(book.bookId)}>
                                        <div className={styles['list-img']} style={{ backgroundImage: `url(${BookContentRepository.getCoverUrls({ bookId: book.bookId })}),url(assets/img/cover.jpg)` }}></div>
                                        <div className={styles['list-title']}>{book.display_name}</div>
                                    </div>
                                    <div className={styles['list-buttonBox']}>
                                        <div>
                                            <div className={styles['list-button']} onClick={() => openBook(book)}>班級紀錄</div>
                                            <div className={styles['list-button']} onClick={() => renewBook(book.bookId)}>續用上次紀錄</div>
                                        </div>
                                        <div>
                                            <div className={classnames(styles['list-button'], styles['big'])} onClick={() => createNewCourse(book.bookId)}>直接上課</div>
                                        </div>
                                    </div>
                                </li>
                            )
                        ) :
                            (
                                <div className={styles.default}>
                                    <div className={styles.title}>目前尚未有任何書本</div>
                                    <div className={styles.sub_title}>請前往書城訂購您要的書本</div>
                                    <div className={styles.go_box}>
                                        <a href="https://mall.oneclass.com.tw/" target="_blank" rel="noopener noreferrer" className={styles.go_onemall}>前往書城</a>
                                    </div>
                                </div>
                            )
                        }
                    </ul>
                    {
                        (role === Roles.TEACHER || role === Roles.GUEST || role === Roles.STUDENT || role === Roles.PARENT) && (<div>
                            <DialogModule
                                open={isAnnotationTabOpen}
                                setOpen={setAnnotationTabOpen}
                                content={TabDialogContent}
                                action={TabDialogAction}
                            />
                            <DialogModule
                                open={inputDialogOpen}
                                setOpen={setInputDialogOpen}
                                content={InputDialogContent}
                                action={InputDialogAction}
                            />
                            <DialogModule
                                open={progressOpen}
                                content={ProgressContent}
                            />
                            <DialogModule
                                open={loadingOpen}
                                content={LoadingContent}
                            />
                        </div>)
                    }

                    <Snackbar
                        anchorOrigin={{ vertical, horizontal }}
                        key={`${vertical},${horizontal}`}
                        open={open}
                        onClose={handleClose}
                    >
                        <Button variant="contained" style={{ backgroundColor: "#f50057", color: "#ccc", width: 200, height: 70, fontSize: 32 }} >
                            請登入
                    </Button>
                    </Snackbar>
                </div>
            </div>
            <Footer />
        </>
    );
};

const { BookshelfRepository } = Repository;
const Bookshelf = () => {
    return BookshelfRepository.isShowBookStoreComponent() ? (
        <BookshelfWeb />
    ) : (
            <ElectronBookShlef />
        )
}

export default Bookshelf;