import { useCallback } from 'react';
import { BaseReaderStrategy } from './BaseReaderStrategy';
import Repository from 'repositories/Repository';
const { ReaderToolBarRepository } = Repository;

export const useGuestStrategy = () => {
  const getReaderToolComponents = useCallback((readerToolComponents) => {
    return BaseReaderStrategy.getAcceptedReaderToolComponents(readerToolComponents, ReaderToolBarRepository.getGuestReaderToolBarWhitelist());
  }, []);

  const isReaderSwipeable = useCallback(() => {
    return true;
  }, []);

  const isReaderSwitchPageAvailable = useCallback(() => {
    return true;
  }, []);

  const syncAnnotation = useCallback((id) => {
    // Do nothing
  }, []);

  const isInteractiveObjectTriggerable = useCallback(({ contentType }) => {
    return true;
  }, []);

  return {
    name: 'GuestStrategy',
    getReaderToolComponents,
    isReaderSwipeable,
    isReaderSwitchPageAvailable,
    syncAnnotation,
    isInteractiveObjectTriggerable
  };
};
