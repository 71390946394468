import React, { useCallback, useState, useEffect } from 'react';
import { DefaultPanelColors, DefaultPanelBackgroundColors } from 'constants/colors';
import ColorSelector from 'components/Painter/ColorSelector';
import Dropdown from 'components/common/Dropdown';
import Icon from 'components/Icon';
import { EventBus } from 'events/EventBus';
import { CanvasEvent, PainterEvent } from 'events/EventTypes';
import { useStore, StoreTypes } from 'context';
import classnames from 'classnames';
import styles from './index.module.scss';


const fontSizes = [12,16,20,24,28,32,36,48,72].map(size => ({
  value: size,
  label: size
}));

const fontStyleType = {
  normal:'normal',
  italic:'italic'
}

const fontWeightType = {
  normal:'normal',
  bold: 'bold'
}

const TextPanel = ({ eventBusType, activeCanvasObject }) => {
  const [{ insertTextTool }] = useStore(StoreTypes.canvas);
  const [isDirty,setIsDirty] = useState(false);

  useEffect(()=>{
    isDirty && setIsDirty(!isDirty)
  },[isDirty])

  const modifyCanvasObjectProperty = useCallback((propertyName, value )=>{
    if (activeCanvasObject) {
      EventBus.emit({
          eventBusType,
          event: CanvasEvent.ModifyCanvasObjectPropertyEvent,
          payload: { 
            object:activeCanvasObject, 
            property: propertyName, 
            value 
          }
      });
      setIsDirty(true)
    }
  },[activeCanvasObject, eventBusType])



  const fontSizeChangeHandler = useCallback(value => {
    const fontSizeValue = parseInt(value);
    modifyCanvasObjectProperty('fontSize',fontSizeValue)
    EventBus.emit({ event: PainterEvent.ChangeTextFontSizeEvent, payload: { fontSize: fontSizeValue } });
  },[modifyCanvasObjectProperty])

  const toggleFontStyleHandler = useCallback(value => () => {
    const fontStylevValue = value === fontStyleType.normal ? fontStyleType.italic : fontStyleType.normal;
    modifyCanvasObjectProperty('fontStyle', fontStylevValue)
    EventBus.emit({ event: PainterEvent.ChangeTextFontStyleEvent, payload: { fontStyle: fontStylevValue } });
  },[modifyCanvasObjectProperty])

  const toggleFontWeightHandler = useCallback(value => () =>{
    const fontWeightValue = value === fontWeightType.normal ? fontWeightType.bold : fontWeightType.normal;
    modifyCanvasObjectProperty('fontWeight', fontWeightValue)
    EventBus.emit({ event: PainterEvent.ChangeTextFontWeightEvent, payload: { fontWeight: fontWeightValue } });
  },[modifyCanvasObjectProperty])

  const toggleUnderlineHandler = useCallback(value => () =>{
    const underlineBoolean = value ? false : true;
    modifyCanvasObjectProperty('underline', value ? false : true)
    EventBus.emit({ event: PainterEvent.ChangeTextUnderlineEvent, payload: { underline: underlineBoolean } });
  },[modifyCanvasObjectProperty])

  const onColorChangeHandler = useCallback(value=>{
    modifyCanvasObjectProperty('fill', value)
    EventBus.emit({ event: PainterEvent.ChangeTextColorEvent, payload: { color: value } });
  },[modifyCanvasObjectProperty])

  const onBgColorChangeHandler = useCallback(value=>{
    modifyCanvasObjectProperty('backgroundColor', value)
    EventBus.emit({ event: PainterEvent.ChangeTextBgColorEvent, payload: { backgroundColor: value } });
  },[modifyCanvasObjectProperty])

  return activeCanvasObject && (
    <div className={styles.textPanel}>
      <div className={styles.group}>
        <div className={styles.title}>文字樣式</div>
        <div className={styles.textStyles}>
          <Dropdown className={styles.fontSizeSelector} data={fontSizes} value={insertTextTool.fontSize} onChange={fontSizeChangeHandler}>
            {
              fontSizes.map(option => (
                <div key={option.value} className={classnames(styles.option, { [styles.active]: insertTextTool.fontSize === option.value })}>
                  <span style={{height: option.value}}>{option.value}</span>
                </div>
              ))
            }
          </Dropdown>
          <div 
            className={classnames(styles.textStyle,{ [styles.active]: insertTextTool.fontWeight === fontWeightType.bold })} 
            onClick={toggleFontWeightHandler(insertTextTool.fontWeight)}
          >
            <Icon type="svg" name="Bold" />
          </div>
          <div 
            className={classnames(styles.textStyle,{ [styles.active]: insertTextTool.fontStyle === fontStyleType.italic })} 
            onClick={toggleFontStyleHandler(insertTextTool.fontStyle)}
          >
            <Icon type="svg" name="Italic" />
          </div>
          <div 
            className={classnames(styles.textStyle,{ [styles.active]: insertTextTool.underline })} 
            onClick={toggleUnderlineHandler(insertTextTool.underline)}
          >
            <Icon type="svg" name="Underline" />
          </div>
        </div>
      </div>
      <div className={styles.group}>
        <div className={styles.title}>文字顏色</div>
        <ColorSelector colors={DefaultPanelColors} value={insertTextTool.fill} onChange={onColorChangeHandler} />
      </div>
      <div className={styles.group}>
        <div className={styles.title}>文字底色</div>
        <ColorSelector 
          colors={DefaultPanelBackgroundColors} 
          value={insertTextTool.backgroundColor} 
          customizedColor={false} 
          transparentOption={true}
          onChange={onBgColorChangeHandler}
        />
      </div>
    </div>
  )
}

export default TextPanel;