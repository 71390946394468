import React from 'react';
import Radio from 'components/common/Radio';
import { PainterType, LineType } from 'constants/painterTypes';
import styles from './index.module.scss';
import classnames from 'classnames';
import { useCallback } from 'react';

const LineTypeData = [
  { label: '實線', value:LineType.Line },
  { label: '虛線', value:LineType.Dash }
];


const PainterOptionSelector = ({ 
  lineType = PainterType.Line, 
  painterType,
  onLineTypeChange = () => {},
}) => {

  const getRadioStatus = useCallback(()=>{
    return lineType === LineType.Line && painterType === PainterType.Pen
  },[lineType, painterType])

  const lineTypeChangeHandler = useCallback(value => {
    !getRadioStatus() && onLineTypeChange(value)
  },[getRadioStatus, onLineTypeChange])

  return (
    <div className={styles.painterOptionSelector}>
      <div className={styles.radio}>
        {LineTypeData.map(item => (
          <Radio
            className={styles.radio}
            name="painterOptionSelector"
            value={item.value.toString()}
            currentValue={lineType}
            disabled={painterType === PainterType.Pen}
            onChange={e => lineTypeChangeHandler(e.target.value)}
            label={<div className={styles.label}>{item.label}</div>}
          />
        ))}
      </div>
    </div>
  );
};
export default PainterOptionSelector;
