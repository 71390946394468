
import React, { useEffect ,useState ,useCallback} from 'react';
import { Roles } from 'constants/role';
import {
    RemoteVideo,
    useRosterState,
    useRemoteVideoTileState,
  } from 'amazon-chime-sdk-component-library-react';
  
  const LiveStreamFullVideo = React.memo(({ role, fullScreenTarget ,isFullVideo}) => {
    const [attendeeId, setAttendeeId] = useState('');
    const { roster } = useRosterState();
    const { attendeeIdToTileId } = useRemoteVideoTileState();
  
    const getAttendTarget = useCallback((roleTarget) => {
      return Object.values(roster).find(item => {
        const role = item.externalUserId.split("$")[1]
        return (role !== Roles.ADMIN && role === roleTarget)
      });
    }, [roster])
  
    useEffect(() => {
      let roleTarget// = role === Roles.TUTOR ? Roles.TUTOR_USER : Roles.TUTOR
      switch(role){
        case Roles.TUTOR:
          roleTarget=Roles.TUTOR_USER
        break;
        case Roles.TUTOR_USER:
          roleTarget=Roles.TUTOR
        break;
        case Roles.ONECLASS_TEACHER:
          roleTarget=Roles.ONECLASS_STUDENT
        break;
        case Roles.ONECLASS_STUDENT:
          roleTarget=Roles.ONECLASS_TEACHER
        break;
        default:
          break;
      }
      const attendTarget = getAttendTarget(role !== Roles.ADMIN ? roleTarget : fullScreenTarget)
      if (attendTarget) {
        setAttendeeId(attendTarget.chimeAttendeeId)
      }
    }, [getAttendTarget, roster, role, fullScreenTarget])
  
    return attendeeId && attendeeIdToTileId[attendeeId] ?
      <>
        <RemoteVideo tileId={attendeeIdToTileId[attendeeId]} />
      </>
      : null
  })


export default LiveStreamFullVideo;