import React, { useCallback, useRef, useEffect ,useState} from 'react';
import Icon from 'components/Icon';
import { useOutsideAlerter } from 'customHooks/outsideAlerter';
import classnames from 'classnames';
import styles from './index.module.scss';
import { ReaderEvent } from 'events/EventTypes';
import { EventBus } from 'events/EventBus';

const StudentList = ({
  studentAttendees,
  stageCount,
  orderStudent,
  unOrderStudent,
  attendeeIdToTileId,
  showMessageDialog,
  onClose
}) => {
  const [isAllStudentMute, setAllStudentMute] = useState(false);
  const studentListRef = useRef();
  const [studentInfo,setStudentInfo]=useState(null)
  useOutsideAlerter(studentListRef, onClose);
  const orderHandler = useCallback(({
    isActive,
    attendeeId,
    stageCount
  }) => {
    if (isActive) {
      unOrderStudent(attendeeId)
    } else {
      if (stageCount < 7) {
        orderStudent(attendeeId)
      } else {
        showMessageDialog()
      }
    }
  }, [orderStudent, showMessageDialog, unOrderStudent])


  const studentMuteContorl=(isMute)=>{
    setAllStudentMute(isMute)
    EventBus.emit({ event: ReaderEvent.sendStudenMuteEvent, payload: { isMute } });
  }

  useEffect(()=>{
    (async () => {
      setStudentInfo(await studentAttendees)
    })()
  },[studentAttendees])


  return <div className={styles.studentList} ref={studentListRef}>
    <div className={styles.header}>
      <div className={styles.title}>學生名單</div>
      <div className={styles.close} onClick={onClose}>
        <Icon type="svg" name="Close" />
      </div>
    </div>
    <div className={styles.content}>
      {
        studentInfo&&studentInfo.map(student => {
          const { chimeAttendeeId: attendeeId, name } = student;
          const isActive = attendeeIdToTileId[attendeeId];
          return (
            <div key={attendeeId} className={classnames(styles.student, { [styles.active]: isActive })}>
              <div className={styles.name}>{name}</div>
              <div className={styles.controls}>
                <div className={styles.control} onClick={() => orderHandler({ isActive, attendeeId, stageCount })}>
                  <Icon type="svg" name="PickUp" />
                </div>
              </div>
            </div>
          )
        })
      }
    </div>
    <div className={styles.footer}>
      <div className={styles.info}>
        <div className={styles.label}>房間人數：</div>
        <div className={styles.count}>{studentInfo&&(studentInfo.length || 0 )}</div>
      </div>
      <div className={styles.info}>
        <div className={styles.label}>上台人數：</div>
        <div className={styles.count}>{stageCount}</div>
      </div>
      <div className={styles.info}>
        <button
          className={styles.soundControlButton}
          onClick={()=>studentMuteContorl(!isAllStudentMute)}
        >
          {
            isAllStudentMute?'全靜音':'開放討論'
          }
        </button>
      </div>
    </div>
  </div>
}


export default StudentList;