
import React, { useState, useEffect, useContext } from 'react';
import { useStore, StoreTypes } from 'context';
import LiveStreamLocalVideo from 'components/LiveStreamLocalVideo'
import ChatRoom from 'components/ChatRoom';
import classnames from 'classnames';
import DialogModule from 'components/Dialogs/'
// import StudentRaiseButton from './StudentRaiseButton'
import StudentList from 'components/LiveStreamStudentList';
import styles from './index.module.scss';
import { useOrderStudent } from 'customHooks/orderStudent';
import { useRemoteVideoTileState, useRosterState ,useContentShareControls} from 'amazon-chime-sdk-component-library-react';
import LiveStreamRemoteVideoGroup from 'components/LiveStreamRemoteVideoGroup';
import LiveStreamEmojiCounts from 'components/LiveStreamEmojiCounts';
import LiveStreamRemoteVideo from 'components/LiveStreamRemoteVideo';
import { Roles } from 'constants/role';
import { ReaderEvent } from 'events/EventTypes';
import { EventBus } from 'events/EventBus';
import Icon from 'components/Icon';
import { Context as AttendeeContext } from 'providers/AttendeeProvider';
import { useStudentList } from 'customHooks/studentList';
import Button from '@material-ui/core/Button';

// const StudentListModal = ({ setStudentListOpen, stageCount }) => {
//   return (
//     <div className={styles.listDialogBottom}>
//       <div className={styles.bottomText}>目前上台人數:{stageCount}</div>
//       <div
//         className={styles.closeListDialog}
//         onClick={() => {
//           setStudentListOpen(false)
//         }}
//       >
//         關閉
//       </div>
//     </div>
//   )
// };


const MessageDialogAction = ({ setMessageOpen }) => (
  <div className={styles.closeDialog}
    onClick={() => setMessageOpen(false)}
  >
    關閉
  </div>
)



const MessageDialogContent = () => (
  <div>
    已超過舉手人數
  </div>
);


const LiveStreamMasterPanel = () => {
  const [students, setStudents] = useState(null);
  const [{ isMultiple, isFullVideo }] = useStore(StoreTypes.course);
  //const attendeeContext = useContext(AttendeeContext)
  const studentAttendees = useStudentList();
  const { tiles, size: stageCount } = useRemoteVideoTileState();
  const { attendeeIdToTileId } = useRemoteVideoTileState();


  const [studentListOpen, setStudentListOpen] = useState(false);
  const [messageOpen, setMessageOpen] = useState(false);
  const [videoAttendees, setVideoAttendees] = useState(new Set());
  const { roster } = useRosterState();

  const [onCourseDialog, setOnCourseDialog] = useState(true);
  const { toggleContentShare, isLocalUserSharing, isLocalShareLoading } = useContentShareControls();

  useEffect(() => {
    if (isMultiple) return;
    const studentsInfo = Object.values(roster).find(item => item.externalUserId.split("$")[1] === Roles.TUTOR_USER);
    setStudents(studentsInfo)
  }, [isMultiple, students, roster])

  const showMessageDialog = () => {
    setMessageOpen(true)
  }

  const { orderStudent, unOrderStudent } = useOrderStudent();
  //const studentAttendees = Object.values(attendeeContext.attendee || {}) || [];

  const videoFullScreenHandler = (isFull) => {
    EventBus.emit({ event: ReaderEvent.sendFullVideoEvent, payload: { isFullVideo: isFull } });
    if (isFull && (isLocalUserSharing || isLocalShareLoading)) {
      toggleContentShare();
    }
  }

  const toggleContentShareHandler = () => {
    setOnCourseDialog(false)
    toggleContentShare();
    if (isFullVideo && !(isLocalUserSharing || isLocalShareLoading)) {
      videoFullScreenHandler(false)
    }
  }

  const dialogContent = <div className={styles.title}>
    <div>提醒</div>
    <div>教師建議使用「螢幕分享」功能上課，點選『確定』後開始「螢幕分享」，iPad 等行動裝置暫不支援，請點『取消』。</div>
  </div>;
  const dialogAction = <div className={styles.ok}>
    <Button
      style={{
        border: "solid",
        color: "white",
        backgroundColor: "cadetblue",
        borderWidth: 2,
        marginRight: "2%"
      }}
      onClick={() => {
        toggleContentShareHandler()
      }}
      color="primary"
    >
      確定
        </Button>
    <Button
      style={{
        border: "solid",
        color: "white",
        backgroundColor: "cadetblue",
        borderWidth: 2
      }}
      onClick={() => {
        setOnCourseDialog(false)
      }}
      color="primary"
    >
      取消
        </Button>
  </div>;

  return (
    <div className={classnames(styles.discussionPanel, styles.wrapper)}>
      <div id="content" className={styles.chatContent}>
        <DialogModule
          style={{ paddingBottom: '20px', display: 'flex', justifyContent: 'center', zIndex: 10000 }}
          open={onCourseDialog}
          content={dialogContent}
          action={dialogAction}
        />
        <div className={styles.localVideo}>
          {
            <LiveStreamLocalVideo streaming={true} />
          }

        </div>
        {
          isMultiple ? (
            <>
              <div className={styles.remoteVideoContainer}>
                <LiveStreamRemoteVideoGroup />
              </div>
              <div className={styles.controls} onClick={() => setStudentListOpen(true)}>
                <div className={styles.studentListbutton} >
                  學生名單
              </div>
              </div>
            </>
          ) : (
              <>
                <div className={styles.singleStudentVideo}>
                  <LiveStreamRemoteVideo />
                </div>
                <LiveStreamEmojiCounts />
              </>
            )
        }
        <div className={classnames(styles.chatRoomWrapper, { [styles.single]: !isMultiple })}>
          <ChatRoom />
        </div>
      </div>
      {/* <DialogModule
        style={{ zIndex: 10000 }}
        open={studentListOpen}
        setOpen={setStudentListOpen}
        content={
          <ListNameTextField
            studentAttendees={studentAttendees}
            stageCount={stageCount}
            showMessageDialog={showMessageDialog}
          />
        }
      />
    */}

      <DialogModule
        style={{ zIndex: 10000 }}
        open={messageOpen}
        setOpen={setMessageOpen}
        content={
          <MessageDialogContent />
        }
        action={
          <MessageDialogAction setMessageOpen={setMessageOpen} />
        }
      />
      {
        studentListOpen && (
          <StudentList
            studentAttendees={studentAttendees}
            stageCount={stageCount}
            showMessageDialog={showMessageDialog}
            orderStudent={orderStudent}
            unOrderStudent={unOrderStudent}
            attendeeIdToTileId={attendeeIdToTileId}
            onClose={() => setStudentListOpen(false)}
          />
        )
      }
    </div>
  )
}

export default LiveStreamMasterPanel;
