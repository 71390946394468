import React from 'react';
import styled from 'styled-components';

const StyledPanel = styled.div`
  position: absolute;
  left: 50%;
  top: -6px;
  padding: 16px 32px;
  transform: translateX(-50%) translateY(-100%);
  background: #232323;
  color: #fff;
  box-shadow: 0px 10px 24px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  z-index: 10000;
  cursor: default;
  &:after {
    content: "";
    position: absolute;
    left: 50%;
    top: 100%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 6px 6px 0 6px;
    border-color: #232323 transparent transparent transparent;
  }
`;

const ReaderToolCollectPanel = ({ children, isShow = false }) => {
  return isShow && (
    <StyledPanel>
      {children}
    </StyledPanel>
  )
};


export default ReaderToolCollectPanel;