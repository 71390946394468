import React from 'react';
import { useRemoteVideoTileState, RemoteVideo } from 'amazon-chime-sdk-component-library-react';
import { useStore, StoreTypes } from 'context';

import styles from './index.module.scss';

const LiveStreamRemoteVideoGroup = () => {
  const [{ teacherAttendeeId }] = useStore(StoreTypes.course);

  const { tiles, attendeeIdToTileId } = useRemoteVideoTileState();

  return (
    <div className={styles.remoteVideoGroup}>
      <div className={styles.remoteVideo}>
        {
          tiles
          .filter(tileId => tileId !== attendeeIdToTileId[teacherAttendeeId])
          .map(tileId => <RemoteVideo tileId={tileId} />)
        }
      </div>
    </div>
  );
};


export default LiveStreamRemoteVideoGroup;