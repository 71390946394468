import { useCallback, useEffect, useState } from 'react';
import { useStore, StoreTypes } from 'context';
import * as types from 'constants/actionTypes';
import firebase from 'firebase/app';
import 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/database';
import "firebase/storage";

const oneClassFirebaseConfig = {
  apiKey: process.env.REACT_APP_ONECLASS_API_KEY,
  authDomain: process.env.REACT_APP_ONECLASS_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_ONECLASS_DATABASE_URL,
  projectId: process.env.REACT_APP_ONECLASS_PROJECT_ID,
  storageBucket: process.env.REACT_APP_ONECLASS_STORAGE_BUCKET
};

export const useFirebase = (config = oneClassFirebaseConfig) => {
  const [, courseDispatch] = useStore(StoreTypes.course);

  const firebaseInitialize = useCallback(() => {
    if (!config) return;
    let admin = firebase.apps.length > 0 ? firebase.apps.find(app => app.name === 'oneclass') : null

    if (!admin) {
      admin = firebase.initializeApp(config, 'oneclass');
    }

    admin.auth().signInAnonymously()
      .then(() => {
        //setFirestore(admin.firestore());
        courseDispatch({ type: types.SET_COURSE_FIRESTORE, firestore: admin })
      })
      .catch(err => console.error('sign in anonymously error', err));
  }, [config, courseDispatch])


  // useEffect(() => {
  //   if (!config) return;
  //   console.log('firebase initialize')
  //   initialize();
  // }, [config, initialize])


  return { firebaseInitialize }
}