export const SideBarType = {
  LEFT: 'LEFT',
  RIGHT: 'RIGHT',
  PAGE_SEARCHER: 'PAGE_SEARCHER'
};

export const ToolBarDirection = {
  TOP: 'top',
  BOTTOM: 'bottom',
  LEFT: 'left',
  RIGHT: 'right',
};

export const ReaderToolType = {
  BackToCatalog: 'BackToCatalog',
  Menu: 'Menu',
  Bookshelf: 'Bookshelf',
  PageMode: 'PageMode',
  Drag: 'Drag',
  Painter: 'Painter',
  Select: 'Select',
  Painting: 'Painting',
  Highlighter: 'Highlighter',
  Shape: 'Shape',
  Line: 'Line',
  DeleteAll: 'DeleteAll',
  Delete: 'Delete',
  // ArrowLeft: 'ArrowLeft',
  // ArrowRight: 'ArrowRight',
  PageSearcher: 'PageSearcher',
  FourZoomIn: 'FourZoomIn',
  AllScreen: 'AllScreen',
  IntervalZoomIn: 'IntervalZoomIn',
  Whiteboard: 'Whiteboard',
  NumberPicker: 'NumberPicker',
  Img: 'Img',
  Text: 'Text',
  SVG: 'SVG',
  AttendeeNumber: 'AttendeeNumber',
  Bookmark: 'Bookmark',
  ReaderOption: 'ReaderOption',
  Activity: 'Activity',
  OpenActivity: 'OpenActivity',
  ExportInteractiveObject: 'ExportInteractiveObject',
  RightSideBar: 'RightSideBar',
  Mark: 'Mark',
  FullWidth: 'FullWidth',
  OfflineReaderToolBox: 'OfflineReaderToolBox',
  StickyNote: 'StickyNote',
  Expression: 'Expression',
  MathTools: 'MathTools',
  Toolbox: 'Toolbox',
  Link: 'Link',
  FullScreen: 'FullScreen',
  Timer: 'Timer',
  Stopwatch: 'Stopwatch',
};

export const ReaderZoomType = {
  LeftTop: "LeftTop",
  RightTop: "RightTop",
  LeftBottom: "LeftBottom",
  RightBottom: "RightBottom",
  OriginZoom: "OriginZoom",
  AreaZoom: "AreaZoom",
  WheelZoom: "WheelZoom",
  PanZoom: "PanZoom",
  RangeZoom: "RangeZoom",
};

export const DefaultLeftReaderTools = [
  ReaderToolType.BackToCatalog,
  ReaderToolType.Toolbox,
];


export const SVGCanvasSwitchType = {
  SVG: "SVG",
  CANVAS: "CANVAS"
};

export const DefaultReaderTools = [
  ReaderToolType.Expression,
  ReaderToolType.Drag,
  ReaderToolType.Painting,
  ReaderToolType.Highlighter,
  ReaderToolType.DeleteAll,
  ReaderToolType.Delete,
  ReaderToolType.ArrowLeft,
  ReaderToolType.PageSearcher,
  ReaderToolType.ArrowRight,
  ReaderToolType.FourZoomIn,
  ReaderToolType.AllScreen,
  ReaderToolType.IntervalZoomIn,
  ReaderToolType.FullScreen,
  ReaderToolType.NumberPicker,
  ReaderToolType.Select,
  ReaderToolType.StickyNote,
  ReaderToolType.Link,
  ReaderToolType.Whiteboard,
  ReaderToolType.SVG,
  ReaderToolType.Text,
  ReaderToolType.Img,
];

export const DefaultMiddleReaderTools = [
  ReaderToolType.Expression,
  ReaderToolType.Drag,
  ReaderToolType.Painting,
  ReaderToolType.Highlighter,
  ReaderToolType.Shape,
  ReaderToolType.DeleteAll,
  ReaderToolType.Delete,
  ReaderToolType.ArrowLeft,
  ReaderToolType.PageSearcher,
  ReaderToolType.ArrowRight,
  ReaderToolType.FourZoomIn,
  ReaderToolType.AllScreen,
  ReaderToolType.IntervalZoomIn,
  ReaderToolType.FullScreen,
  ReaderToolType.NumberPicker,
  ReaderToolType.Select,
  ReaderToolType.StickyNote,
  ReaderToolType.Text,
  ReaderToolType.Whiteboard,
  ReaderToolType.Img,
  ReaderToolType.Mark,
  ReaderToolType.Line,
  ReaderToolType.SVG,
  ReaderToolType.PageMode,
  ReaderToolType.StickyNote,
  ReaderToolType.Link,
  ReaderToolType.MathTools,
  ReaderToolType.Menu,
  ReaderToolType.Bookshelf,
  ReaderToolType.ReaderOption,
  ReaderToolType.Timer,
  ReaderToolType.Stopwatch,
];

export const DefaultRightReaderTools = [
  ReaderToolType.OfflineReaderToolBox,
  ReaderToolType.AttendeeNumber,
  ReaderToolType.Bookmark,
  ReaderToolType.Activity,
  // OpenActivity,
  ReaderToolType.ExportInteractiveObject,
  // RightSideBar,
];

export const ToolboxCategory = {
  Settings: 'Settings',
  Controls: 'Controls',
  EditTools: 'EditTools',
  TeachingTools: 'TeachingTools',
  MathTools: 'MathTools',
  OfflineTools: 'OfflineTools'
};

export const DefaultToolboxCategory = [
  ToolboxCategory.Settings,
  ToolboxCategory.Controls,
  ToolboxCategory.EditTools,
  ToolboxCategory.TeachingTools,
  ToolboxCategory.MathTools
]

export const ReaderToolbox = {
  Settings: [
    ReaderToolType.Bookshelf,
    ReaderToolType.SVG,
  ],
  Controls: [
    ReaderToolType.BackToCatalog,
    ReaderToolType.Drag,
    ReaderToolType.Select,
    ReaderToolType.PageMode,
    ReaderToolType.PageSearcher,
    ReaderToolType.ArrowLeft,
    ReaderToolType.ArrowRight,
    ReaderToolType.FourZoomIn,
    ReaderToolType.IntervalZoomIn,
    ReaderToolType.AllScreen,
    ReaderToolType.FullScreen,
    ReaderToolType.Menu,
    ReaderToolType.ReaderOption,
  ],
  EditTools: [
    ReaderToolType.Painting,
    ReaderToolType.Highlighter,
    ReaderToolType.Shape,
    ReaderToolType.Line,
    ReaderToolType.DeleteAll,
    ReaderToolType.Delete,
    ReaderToolType.Mark,
    ReaderToolType.Link,
  ],
  TeachingTools: [
    ReaderToolType.Expression,
    ReaderToolType.NumberPicker,
    ReaderToolType.StickyNote,
    ReaderToolType.Whiteboard,
    ReaderToolType.Timer,
    ReaderToolType.Stopwatch,
  ],
  MathTools: [
    ReaderToolType.MathTools
  ],
  OfflineTools: []
}