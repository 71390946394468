export const DefaultExtraFields = {
  Id: 'id',
  Extra: 'extra',
  LockMovementX: 'lockMovementX',
  LockMovementY: 'lockMovementY',
  HasControls: 'hasControls'
};

export const ExtraFieldsForEditor = {
  Id: 'id',
  Style: 'style',
  Src: 'src',
  ContentType: 'contentType',
  ControlObject: 'controlObject',
  PageIndex: 'pageIndex',
  VideoInfo: 'videoInfo',
  AudioInfo: 'audioInfo',
  PartialSrc: 'partialSrc',
  AreaZoomInfo: 'areaZoomInfo',
  InternalHtmlInfo: 'internalHtmlInfo',
  Stage: 'stage',
  ControlStage: 'controlStage',
  Opacity: 'opacity',
  RevealStage: 'revealStage',
  WordGridInfo: 'wordGridInfo',
  VolumeOneWords: 'volumeOneWords',
  WordExampleInfo: 'wordExampleInfo',
  TransltaionInfo: 'transltaionInfo',
  VocabularyInfo: 'vocabularyInfo',
  SubmenuInfo: 'submenuInfo',
  SynopsisInfo: 'synopsisInfo',
  DefaultOpacity: 'defaultOpacity',
  HearingInfo: 'hearingInfo',
  ListeningInfo: 'listeningInfo',
  AboutTheAuthorInfo: 'aboutTheAuthorInfo',
  ReadAloudInfo: 'readAloudInfo',
  MouseOver: 'mouseOver',
  ZhuyinLessonInfo: 'zhuyinLessonInfo',
  ZhuyinImageInfo: 'zhuyinImageInfo',
  IsWebVisible: 'isWebVisible',
  IsOfflineVisible: 'isOfflineVisible',
  ZhuyinWordInfo: 'zhuyinWordInfo',
  IsAudioHighlighter: 'isAudioHighlighter',
  IsClickable: 'isClickable',
  IsShowAudioControlBar: 'isShowAudioControlBar',
  MathTools: 'mathTools',
  IsFollowRead: 'isFollowRead',
};
