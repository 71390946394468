import React from 'react';
import styles from './index.module.scss';
import LineWidthSelector from 'components/Painter/LineWidthSelector';
import FormGroup from 'components/common/FormGroup';
import Icon from 'components/Icon';
import { EventBus } from 'events/EventBus';
import { CanvasEvent } from 'events/EventTypes';
import * as types from 'constants/actionTypes';
import ModifyColorSelector from 'components/Tools/ModifyColorSelector';
import { DefaultBorderColors, DefaultFillColors } from 'constants/colors';
import { useStore, StoreTypes } from 'context';
import classnames from 'classnames';

const ObjectPainterPanel = () => {
  const reducers = useStore();
  const [{ painterPosition }, sideToolDispatch] = useStore(StoreTypes.sideTool);
  const [{ activeCanvasObject }] = reducers[StoreTypes.canvas];
  const closeHandler = () => {
    sideToolDispatch({
      type: types.SET_PAINTER_TOOL_SHOW,
      isPainterPanelShow: false
    });
  }
  const onLineWidthChange = value => {
    const intLineWidth = parseInt(value);
    if (activeCanvasObject) {
      EventBus.emit({
        event: CanvasEvent.ModifyCanvasObjectPropertyEvent,
        payload: { activeCanvasObject, property: "strokeWidth", value: intLineWidth }
      });
    }
  };
  return (
    <div className={classnames(styles.painterMain, styles[painterPosition])}>
      <div className={styles.closeBtn} onClick={() => closeHandler()}>
        <Icon type="svg" name="Close" />
      </div>
      <FormGroup label="線條粗細" vertical>
        <LineWidthSelector value={activeCanvasObject.strokeWidth} onChange={onLineWidthChange} />
      </FormGroup>
      <FormGroup label="線條顏色" vertical>
        <ModifyColorSelector
          propertyName="stroke"
          colors={DefaultBorderColors}
          target={activeCanvasObject}
          opacityMode={true}
          customizedColor={false}
        />
      </FormGroup>
      <FormGroup label="填滿顏色" vertical>
        <ModifyColorSelector
          propertyName="fill"
          colors={DefaultFillColors}
          target={activeCanvasObject}
          customizedColor={false}
        />
      </FormGroup>
    </div>
  );
};

export default ObjectPainterPanel;
