import React, { createContext, useState, useEffect, useCallback } from 'react';
import { useMeetingManager } from 'amazon-chime-sdk-component-library-react';
import { useStore, StoreTypes } from 'context';
import { convertArrayToMap } from 'util/array';

export const Context = createContext({ 
  attendee: {}, 
  getAttendeeByUserId: () => {} 
});


 const Provider = ({ children }) => {
  const meetingManager = useMeetingManager();
  const meetingId = meetingManager.meetingId;
  const [attendee,setAttendee] = useState({});
  const [{ token }] = useStore(StoreTypes.user);

  const getAttendeeByUserId = useCallback(async userId => {
    const response =
        await fetch(`${process.env.REACT_APP_CHIME_API_URL}/getAttendee?meetingId=${meetingId}&userId=${userId}`,{
          method:'GET',
          headers:{
              'Content-Type': 'application/json',
              'Authorization': token
          }
      });
    const { data } = await response.json();
    setAttendee(prev => ({
      ...prev,
      [data.AttendeeId]: {
        ...data
      }
    }));
  },[meetingId, token]);

  useEffect(() => {
    if (!meetingId) return;
    const getAttendeeList = async () => {
        const response =
            await fetch(`${process.env.REACT_APP_CHIME_API_URL}/getAttendees?meetingId=${meetingId}`,{
              method:'GET',
              headers:{
                  'Content-Type': 'application/json',
                  'Authorization': token
              }
          });
        const { data } = await response.json();

        if(data){
          const attendeeIdMap = convertArrayToMap(data,'AttendeeId')
          setAttendee(attendeeIdMap);
        }
    }

    getAttendeeList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[meetingId, token])

  useEffect(() => {
    console.log('attendee',attendee)
  },[attendee])

  return (
    <Context.Provider value={{ attendee, getAttendeeByUserId }}>
      {children}
    </Context.Provider>
  )
 }

 export default Provider;