import { useCallback } from 'react';
import { EventBus } from 'events/EventBus';
import { ReaderEvent } from 'events/EventTypes';
import { InteractiveObjectContentType } from 'constants/interactiveObjectContentTypes';

export const useAreaZoomCommand = () => {
    const execute = useCallback(async ({ json: { areaZoomInfo }, pageIndex, isDoublePageMode, interactiveObjectJSON }) => {
        let areaZoomInteractiveObjects = [];
        let pageIndexInfo = [pageIndex];
        if (isDoublePageMode) {
            if (pageIndex % 2) {
                pageIndexInfo = [pageIndex - 1, pageIndex]
            } else {
                pageIndexInfo = [pageIndex, pageIndex + 1]
            }
        }

        for (let obj in interactiveObjectJSON) {
            if (~pageIndexInfo.indexOf(parseInt(obj))) {
                Object.keys(interactiveObjectJSON[obj]).forEach((objKey) => {
                    const interactiveObject = interactiveObjectJSON[obj][objKey];
                    interactiveObject.contentType === InteractiveObjectContentType.AreaZoom &&
                        areaZoomInteractiveObjects.push(JSON.parse(interactiveObject.areaZoomInfo))
                })
            }
        }

        const areaZoomInfoObject = JSON.parse(areaZoomInfo);

        EventBus.emit({
            event: ReaderEvent.AreaZoomForPageButtonEvent,
            payload: {
                rect: areaZoomInfoObject,
                areaZoomInteractiveObjects: areaZoomInfoObject.step !== "" ? {
                    nowStep: areaZoomInfoObject.step,
                    areaZoomInfos: areaZoomInteractiveObjects.filter((obj) => obj.step !== "").sort((a, b) => (a.step - b.step))
                } : null
            }
        })
    }, []);
    return { execute };
};
