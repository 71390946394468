import { useEffect } from 'react';
import { fabric } from 'fabric';


export const useFabricListener = (eventName,handler) => {
 
    useEffect(()=>{
        fabric.util.addListener(document.body, eventName, handler);
        return ()=>{
            fabric.util.removeListener(document.body, eventName, handler)
        }
    },[eventName, handler])
}