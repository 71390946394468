import { useEnhanceReducer } from 'customHooks/enhanceReducer';

import { EventBus } from 'events/EventBus';
import { ReaderToolsEvent } from 'events/EventTypes';
import * as types from '../constants/actionTypes';
import { DefaultExtraFields, ExtraFieldsForEditor } from 'constants/canvas';
import { PainterMode } from '../constants/painterModes';
import {
  PainterToolType,
  PainterType,
  PainterBrushType,
  LineType
} from 'constants/painterTypes';
import { hexToRgba } from 'util/hexToRgba';
import { fabric } from 'fabric';

const fabricCanvas = new fabric.Canvas('c', {
  isDrawingMode: true,
  skipTargetFind: true,
  selection: true,
  preserveObjectStacking: true
});

const drawingBrushObjectGenerator = (canvas, brush) => {
  return new fabric[brush + 'Brush'](canvas);
};

export const initState = {
  canvasElement: null,
  svgElement: null,
  canvas: fabricCanvas,
  isActive: false,
  isCanvasShow: false,
  svgShow: true,
  painterMode: PainterMode.Selection,
  painterToolType: PainterToolType.Pen,
  painterTool: {
    [PainterToolType.Pen]: {
      originColor: '#E71F1A',
      colorHex: '#E71F1A',
      lineWidth: 4,
      drawingBrush: PainterBrushType.Pencil,
      painterType: PainterType.Pen,
      lineType: LineType.Line,
    },
    [PainterToolType.Highlighter]: {
      originColor: '#E71F1A',
      colorHex: '#faee00',
      lineWidth: 14,
      drawingBrush: PainterBrushType.Pencil,
      painterType: PainterType.Line,
      lineType: LineType.Line,
    },
    [PainterToolType.Shape]: {
      originColor: '#EC078D',
      colorHex: '#EC078D',
      lineWidth: 1,
      drawingBrush: null,
      painterType: PainterType.Rectangle,
      lineType: LineType.Line,
    },
    [PainterToolType.Line]: {
      originColor: '#EC078D',
      colorHex: '#EC078D',
      lineWidth: 1,
      drawingBrush: null,
      painterType: PainterType.Line,
      lineType: LineType.Line,
    }
  },
  insertTextTool: {
    fill: '#000000',
    fontSize: 24,
    fontStyle: 'normal',
    fontWeight: 'normal',
    underline: false,
    backgroundColor: '#FFFFFF'
  },
  fillType: 'hollow',
  // painterType: PainterType.Pen,
  canvasJSON: {},
  saveCanvasTime: 0,
  canvasSVG: {}, // listed by page index,
  extraFields: Object.values(DefaultExtraFields),
  stampType: null,
  activeCanvasObject: null
};

const changePainterMode = (
  { canvas, isActive, painterTool },
  { painterMode, painterToolType = PainterToolType.Pen, isToggle }
) => {
  const { painterType } = painterTool[painterToolType];
  canvas.discardActiveObject().renderAll();
  switch (painterMode) {
    case PainterMode.Painting:
      canvas.selection = false;
      canvas.skipTargetFind = true;
      if (
        painterType === PainterType.Pen ||
        painterType === PainterType.Highlighter
      ) {
        canvas.isDrawingMode = true;
      } else {
        canvas.isDrawingMode = false;
      }
      activeBrushObject({
        canvas,
        painterTool: painterTool[painterToolType],
        painterToolType: painterToolType
      });
      break;
    case PainterMode.InsertText:
    case PainterMode.InsertImage:
    case PainterMode.Stamp:
      canvas.isDrawingMode = false;
      canvas.selection = false;
      canvas.skipTargetFind = true;
      break;
    case PainterMode.Eraser:
    default:
      canvas.isDrawingMode = false;
      canvas.selection = true;
      canvas.skipTargetFind = false;
      break;
  }

  const activeState = isToggle ? !isActive : true;

  return {
    canvas,
    painterMode,
    isActive: activeState,
    painterToolType,
    painterTool: {
      ...painterTool,
      [painterToolType]: painterTool[painterToolType]
    }
  };
};

const changePainterType = (
  { canvas, painterMode, painterToolType, painterTool },
  { painterType }
) => {
  canvas.isDrawingMode = false;
  painterMode = PainterType.Pen ? PainterMode.Painting : PainterMode.Selection;
  if (painterType === PainterType.Pen) {
    canvas.isDrawingMode = true;
  } else {
    canvas.skipTargetFind = true; //canvas中物件不能被選到
    canvas.selection = false; //canvas中不顯示被選中
  }

  return {
    canvas,
    painterMode,
    painterTool: {
      ...painterTool,
      [painterToolType]: {
        ...painterTool[painterToolType],
        painterType
      }
    }
  };
};

const addObject = ({ canvas }, { object, activateObject = true }) => {
  if (canvas.getActiveObjects().map(item => item.id).some((id) => object.id === id)) return { canvas, isActive: true };
  canvas.isDrawingMode = false;
  canvas.add(object);
  if (activateObject) {
    canvas.selection = true;
    canvas.skipTargetFind = false;
    canvas.setActiveObject(object);
  }
  canvas.renderAll();
  return { canvas, isActive: true };
};

const changeBrush = ({ canvas, painterTool, painterToolType }, brush) => {
  const drawingBrushObj = drawingBrushObjectGenerator(canvas, brush);
  const { lineWidth, colorHex } = painterTool[painterToolType];
  drawingBrushObj.width = lineWidth;
  drawingBrushObj.color = colorHex;
  painterTool[painterToolType].drawingBrush = brush;
  activeBrushObject({ canvas, painterTool: painterTool[painterToolType] });

  EventBus.emit({
    event: ReaderToolsEvent.SaveReaderToolSettingsEvent,
    payload: { painterTool: painterTool }
  });
  return {
    canvas,
    isActive: true,
    painterTool: {
      ...painterTool,
      [painterToolType]: painterTool[painterToolType]
    },
    painterToolType
  };
};

const clearActiveObject = ({ canvas }) => {
  canvas.getActiveObjects().forEach(obj => {
    canvas.remove(obj);
  });
  canvas.discardActiveObject().renderAll();
  return { canvas, isActive: true };
};

// switch BrushObject to current painterToolType // Pen or Highlighter
const activeBrushObject = ({ canvas, painterTool, painterToolType }) => {
  const { drawingBrush, colorHex, lineWidth } = painterTool;

  if (drawingBrush) {
    canvas.freeDrawingBrush = drawingBrushObjectGenerator(canvas, drawingBrush);
  }
  canvas.freeDrawingBrush.color =
    painterToolType === PainterToolType.Highlighter
      ? hexToRgba(colorHex, 0.35)
      : colorHex;

  canvas.freeDrawingBrush.width = lineWidth;

};

const eraseAllCanvasObjects = canvas => {
  canvas.clear();
};

export const canvasReducer = (state, action) => {
  switch (action.type) {
    case types.CANVAS_INITIALIZE:
      const { el, height, width, isDrawingMode } = action;
      state.canvas.initialize(el, { height, width, isDrawingMode });

      const tool = state.painterTool[state.painterToolType];
      activeBrushObject({
        canvas: state.canvas,
        painterToolType: state.painterToolType,
        painterTool: tool
      });

      return {
        ...state,
        canvas: state.canvas,
        painterMode: isDrawingMode
          ? PainterMode.Painting
          : PainterMode.Selection
      };
    case types.CANVAS_RESIZE:
      state.canvas.setWidth(action.width);
      state.canvas.setHeight(action.height);
      return { ...state };
    case types.CANVAS_ACTIVATE:
      return { ...state, isActive: true };
    case types.CANVAS_INACTIVATE:
      state.canvas.isDrawingMode = false;
      return {
        ...state,
        canvas: state.canvas,
        painterMode: PainterMode.Selection,
        isActive: false,
        activeCanvasObject: null
      };
    case types.CANVAS_REFRESH:
      return { ...state, canvasSVG: { ...state.canvasSVG } };
    case types.CANVAS_CHANGE_PAINTER_TYPE:
      return { ...state, ...changePainterType(state, action) };
    case types.ADD_OBJECT:
      return { ...state, ...addObject(state, action) };
    case types.CANVAS_CHANGE_STAMP_STATUS:
      return {
        ...state,
        stampType: action.stampType,
        ...changePainterMode(state, action)
      };
    case types.CANVAS_CHANGE_PAINTER_MODE:
      return { ...state, ...changePainterMode(state, action) };
    case types.CANVAS_DRAWING_BRUSH_LINE_WIDTH:
      const { changeLineWidth } = action;
      state.painterTool[state.painterToolType].lineWidth = parseInt(
        changeLineWidth
      );
      state.canvas.freeDrawingBrush.width =
        state.painterTool[state.painterToolType].lineWidth;
      EventBus.emit({
        event: ReaderToolsEvent.SaveReaderToolSettingsEvent,
        payload: { painterTool: state.painterTool }
      });
      return {
        ...state,
        canvas: state.canvas,
        painterTool: state.painterTool,
        isActive: true
      };
    case types.CHANGE_DRAWING_BRUSH:
      return { ...state, ...changeBrush(state, action.changeDrawingBrush) };
    case types.CANVAS_ERASE_OBJECT:
      return { ...state, ...clearActiveObject(state) };
    case types.CANVAS_CHANGE_COLOR:
      const { changeColorHex } = action;
      let { canvas, painterTool, painterToolType } = state;
      const color =
        painterToolType === PainterToolType.Highlighter
          ? hexToRgba(changeColorHex, 0.35)
          : changeColorHex;
      state.canvas.freeDrawingBrush.color = color;
      state.painterTool[state.painterToolType].colorHex = color;
      state.painterTool[state.painterToolType].originColor = changeColorHex;
      EventBus.emit({
        event: ReaderToolsEvent.SaveReaderToolSettingsEvent,
        payload: { painterTool: state.painterTool }
      });
      activeBrushObject({
        canvas,
        painterTool: painterTool[painterToolType],
        painterToolType: state.painterToolType
      });
      return {
        ...state,
        canvas: state.canvas,
        painterTool: state.painterTool,
        isActive: true
      };
    case types.CANVAS_CHANGE_TEXT_COLOR:
      const textColor = action.changeColorRgb;
      state.canvas.freeDrawingBrush.color = textColor;
      state.insertTextTool.fill = textColor;
      EventBus.emit({
        event: ReaderToolsEvent.SaveReaderToolSettingsEvent,
        payload: { insertTextTool: state.insertTextTool }
      });
      return {
        ...state,
        insertTextTool: state.insertTextTool
      };
    case types.CANVAS_CHANGE_TEXT_FONTSIZE:
      const textFontSize = action.changeFontSize;
      state.canvas.freeDrawingBrush.fontSize = textFontSize;
      state.insertTextTool.fontSize = textFontSize;
      EventBus.emit({
        event: ReaderToolsEvent.SaveReaderToolSettingsEvent,
        payload: { insertTextTool: state.insertTextTool }
      });
      return {
        ...state,
        insertTextTool: state.insertTextTool
      };
    case types.CANVAS_CHANGE_TEXT_FONTWEIGHT:
      const textFontWeight = action.changeFontWeight;
      state.canvas.freeDrawingBrush.fontWeight = textFontWeight;
      state.insertTextTool.fontWeight = textFontWeight;
      EventBus.emit({
        event: ReaderToolsEvent.SaveReaderToolSettingsEvent,
        payload: { insertTextTool: state.insertTextTool }
      });
      return {
        ...state,
        insertTextTool: state.insertTextTool
      };
    case types.CANVAS_CHANGE_TEXT_FONTSTYLE:
      const textFontStyle = action.changeFontStyle;
      state.canvas.freeDrawingBrush.fontStyle = textFontStyle;
      state.insertTextTool.fontStyle = textFontStyle;
      EventBus.emit({
        event: ReaderToolsEvent.SaveReaderToolSettingsEvent,
        payload: { insertTextTool: state.insertTextTool }
      });
      return {
        ...state,
        insertTextTool: state.insertTextTool
      };
    case types.CANVAS_CHANGE_TEXT_UNDERLINE:
      const textUnderline = action.changeUnderline;
      state.canvas.freeDrawingBrush.underline = textUnderline;
      state.insertTextTool.underline = textUnderline;
      EventBus.emit({
        event: ReaderToolsEvent.SaveReaderToolSettingsEvent,
        payload: { insertTextTool: state.insertTextTool }
      });
      return {
        ...state,
        insertTextTool: state.insertTextTool
      };
    case types.CANVAS_CHANGE_TEXT_BGCOLOR:
      const textBgColor = action.changeBgColor;
      state.canvas.freeDrawingBrush.backgroundColor = textBgColor;
      state.insertTextTool.backgroundColor = textBgColor;
      EventBus.emit({
        event: ReaderToolsEvent.SaveReaderToolSettingsEvent,
        payload: { insertTextTool: state.insertTextTool }
      });
      return {
        ...state,
        insertTextTool: state.insertTextTool
      };
    case types.CANVAS_CHANGE_LINE_TYPE:
      const { lineType } = action;
      return {
        ...state, painterTool: {
          ...state.painterTool,
          [state.painterToolType]: {
            ...state.painterTool[state.painterToolType],
            lineType
          }
        }
      }
    case types.CANVAS_CHANGE_SHAPE_FILL_TYPE:
      return { ...state, fillType: action.fillType };
    case types.CANVAS_EXPORT_SVG:
      return {
        ...state,
        canvasJSON: { ...state.canvasJSON, ...action.canvasJSON }
      };
    case types.CANVAS_IMPORT_SVG:
      return {
        ...state,
        canvasSVG: { ...state.canvasSVG, ...action.canvasSVG }
      };
    case types.CANVAS_RESTORE_FROM_DB:
      return {
        ...state,
        canvasJSON: action.annotations.reduce((acc, v) => {
          acc[v.pageIndex] = v.annotation;
          return acc;
        }, {})
      };
    case types.SAVE_CANVAS_JSON_TIME:
      return { ...state, saveCanvasTime: action.saveCanvasTime }
    case types.CANVAS_RESET_SVG:
      return { ...state, canvasSVG: { ...initState.canvasSVG } };
    case types.CANVAS_ERASE_ALL:
      eraseAllCanvasObjects(state.canvas);
      return state;
    case types.CANVAS_TURN_ON_EXTRA_FIELDS_FOR_EDITOR:
      return {
        ...state,
        extraFields: [
          ...new Set([
            ...state.extraFields,
            ...Object.values(ExtraFieldsForEditor)
          ])
        ]
      };
    case types.SET_CANVAS_ACTIVE_OBJECT:
      return { ...state, activeCanvasObject: action.activeCanvasObject };
    case types.SET_CANVAS_OBJECT_PROPERTY:
      const { objectProperty } = action;
      const newCanvasObj = { ...state.activeCanvasObject, objectProperty };
      return { ...state, activeCanvasObject: newCanvasObj };
    case types.SET_CANVAS_PAINTER_TOOL:
      return { ...state, painterTool: action.painterTool };
    case types.SET_CANVAS_INSERT_TEXT_TOOL:
      return { ...state, insertTextTool: action.insertTextTool };
    case types.SET_CANVAS_SHOW:
      return { ...state, isCanvasShow: action.isCanvasShow };
    case types.SET_SVG_SHOW:
      return { ...state, svgShow: action.svgShow };
    case types.SET_CANVAS_SVG_ELEMENT:
      const { canvasElement, svgElement } = action
      return { ...state, ...{ canvasElement, svgElement } };
    default:
      return state;
  }
};

const useCanvasReducer = () => useEnhanceReducer(canvasReducer, initState);

export default useCanvasReducer;
