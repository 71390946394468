import { useRosterState ,useMeetingManager} from 'amazon-chime-sdk-component-library-react';
import { Roles } from 'constants/role';
import queryString from 'query-string';

export const getAttendeeInfo = async({meetingId,item}) => {
  const url = queryString.stringifyUrl({
    url: `${process.env.REACT_APP_CHIME_API_URL}/getAttendee`,
    query: {
      meetingId,
      userId: item.externalUserId
    }
  });
  const response = await fetch(url)
  const user = await response.json();
  const { data } = user;
  return Promise.resolve( {
    name: data.Name,
    role: data.Role,
    userId: data.ExternalUserId,
    externalUserId:item.externalUserId,
    chimeAttendeeId:item.chimeAttendeeId,
  })
}

export const useStudentList = async() => {
  const { roster } = useRosterState();
  const meetingManager = useMeetingManager();
  const meetingId = meetingManager.meetingId;
  const attendees = Object.values(roster).filter(item => item.externalUserId.split('$').includes(Roles.ONECLASS_STUDENT));
  // attendees.forEach(item => item.name = item.externalUserId.split('$')[0]);
  

  let arr=[]
  attendees.map(async(item) =>{
    arr.push(getAttendeeInfo({meetingId,item}))
  })

  return await Promise.all(arr).then(val=>{
    return Promise.resolve(val);
  })
};