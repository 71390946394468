/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState,useCallback } from 'react';
import Input from 'components/common/Input';
import styles from './index.module.scss';
import { sendData } from './login';
import { useStore, StoreTypes } from 'context';
import { LOGIN_POPUP} from 'constants/loginTypes';
import { EventBus } from 'events/EventBus';
import { UserEvent } from 'events/EventTypes';
import useSetState from 'customHooks/setState';


const LoginPopup = () => {
    const getRememberLocal = localStorage.getItem('remember');
    const [{popupState},loginDispatch] = useStore(StoreTypes.login);
    const [rememberMe,setRememberMe] = useState(getRememberLocal ? true : false);
    const [errormessage,seterrmessage] = useState('');
    const [ UserParams , setUserParams] = useSetState({
        Account: getRememberLocal || '',
        Password:''
    });
    const [remark,setRemark] = useSetState({
        Account:'',
        Password:''
    });
    const inputList = [
        {
            Title:'用戶名稱',
            Attion:'',
            Input:{
                Type:'text',
                Name:'Account',
                Value:UserParams.Account,
                Placeholder:'請輸入您的用戶名稱'
            },
            Remark:remark.Account
        },
        {
            Title:'密碼',
            Attion:'*大小寫視為不同',
            Input:{
                Type:'password',
                Name:'Password',
                Value:UserParams.Password,
                Placeholder:'請輸入您的用戶密碼'
            },
            Remark:remark.Password
        }
    ];
    const updateUserParams = e => {
        setUserParams({[e.target.name] : e.target.value})
    }
    const Submit = useCallback(async () => {
        setRemark({
            Account : UserParams.Account ? '' : '請輸入帳號或正確的格式',
            Password : UserParams.Password ? '' : '請輸入密碼'
        });
        
        if(UserParams.Account === '' || UserParams.Password === '') return false;

        rememberMe ? localStorage.setItem('remember', UserParams.Account) : localStorage.setItem('remember', '');
        
        const { code , message } = await sendData(UserParams.Account,UserParams.Password);

        if(code === 'SUCCESS'){
            await loginDispatch({type:LOGIN_POPUP,popupState:false});

            EventBus.emit({ event: UserEvent.LoginEvent });

            setUserParams({Account:rememberMe ? UserParams.Account :'', Password:''});

            seterrmessage('');
        }else{
            seterrmessage(message);
        }

    },[UserParams.Account, UserParams.Password, loginDispatch, rememberMe, setRemark, setUserParams]);
    const handleKeyPress = (event) => {
        event.key === 'Enter' &&  Submit();
    }
    const closePopup = (e,className) => {
        const el = e.target.className.trim();
        el === className.trim() && loginDispatch({type:LOGIN_POPUP,popupState:false})
    }
    return (
        <div className={`${styles.loginPopup__background} ${popupState?'':styles.active}`} onClick={ e => { closePopup(e,styles.loginPopup__background) } }>
            <div className={styles.loginPopup__box}>
                <div className={styles.loginPopup__close} onClick={()=>{loginDispatch({type:LOGIN_POPUP,popupState:false})}}>X</div>
                <h5>登入</h5>
                {
                    errormessage?
                    <div className={styles.loginPopup__message}>
                        {errormessage}
                    </div>:''
                }
                {
                    inputList.map((item) => {
                        return (
                            <div className={styles.loginPopup__inputbox} key={item.Input.Name}>
                                <h6>{item.Title}</h6>
                                <div className={styles.loginPopup__attion}>{item.Attion}</div>
                                <Input type={ item.Input.Type } value={ item.Input.Value } name={ item.Input.Name } placeholder={ item.Input.Placeholder } onChange={updateUserParams} onKeyPress={ e => { handleKeyPress(e) } }/>
                                {item.Remark?<span>{ item.Remark }</span>:''}
                            </div>
                        )
                    })
                }
                <label className={styles.loginPopup__remeber}>
                    <input 
                        type="checkbox" 
                        defaultChecked 
                        onClick={()=> { setRememberMe(!rememberMe) }}
                    />
                    <span>記住帳號</span>
                </label>
                <div className={`${styles.loginPopup__btn} ${styles.loginPopup__btnred}`} onClick={ () => Submit() }>
                    <span>登入</span>
                </div>
                <hr/>
                <div className={styles.loginPopup__link}>
                    <a href="https://oneclub.oneclass.com.tw/forgetpassword/" target="blank">忘記密碼</a>
                    <a href="https://oneclub.oneclass.com.tw/signup/" target="blank">註冊帳號</a>
                </div>
            </div>
        </div>
    )
}

export default LoginPopup;