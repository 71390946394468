import { useCallback } from 'react';
import useModal from 'components/common/Modal/useModal';
import { ModalComponentGenerator, ModalComponentType } from 'components/common/Modal/ModalComponentGenerator';
import { useStore, StoreTypes } from 'context';
import Repository from 'repositories/Repository';
const { ExtendedResourceRepository } = Repository;

export const useNumberPickerCommand = () => {
    const [{ bookId }] = useStore(StoreTypes.books);
    const [, { openModal, setModalComponents }] = useModal();

    const execute = useCallback(async () => {
        try {

            const tools = await ExtendedResourceRepository.getToolbarTools({ bookId }) || [];
            const numberSelector = tools.filter(item=>item.type && item.type === 'numberSelector');
            if(numberSelector && numberSelector.length > 0){
                const tool = numberSelector[0];
                return ExtendedResourceRepository.postTools({ bookId, filename: tool.filename })
            } else {
                const component = ModalComponentGenerator.generate(ModalComponentType.NumberPicker);
                setModalComponents(component);
                openModal();
            }

        } catch (e) {
            console.error('NumberPickerCommand execute error', e);
        }
    }, [bookId, openModal, setModalComponents]);

    return { execute };
};
