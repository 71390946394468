export const educationalSystemTable = {
    '國小': {
        Grade: {
            '一年級': ['國語', '數學', '生活', '健體'],
            '二年級': ['國語', '數學', '生活', '健體'],
            '三年級': ['國語', '數學', '自然', '社會', '健體', '藝文', '綜合'],
            '四年級': ['國語', '數學', '自然', '社會', '健體', '藝文', '綜合'],
            '五年級': ['國語', '數學', '自然', '社會', '健體', '藝文', '綜合'],
            '六年級': ['國語', '數學', '自然', '社會', '健體', '藝文', '綜合']
        },
        Subject: {
            '國語': ['一年級', '二年級', '三年級', '四年級', '五年級', '六年級'],
            '數學': ['一年級', '二年級', '三年級', '四年級', '五年級', '六年級'],
            '生活': ['一年級', '二年級'],
            '自然': ['三年級', '四年級', '五年級', '六年級'],
            '社會': ['三年級', '四年級', '五年級', '六年級'],
            '健體': ['一年級', '二年級', '三年級', '四年級', '五年級', '六年級'],
            '藝文': ['三年級', '四年級', '五年級', '六年級'],
            '綜合': ['三年級', '四年級', '五年級', '六年級']
        }
    },
    '國中': {
        Grade: {
            '一年級': ['國文', '英語', '數學', '自然', '地理', '歷史', '公民', '科技', '健體', '綜合'],
            '二年級': ['國文', '英語', '數學', '自然', '地理', '歷史', '公民', '科技', '健體', '綜合'],
            '三年級': ['國文', '英語', '數學', '自然', '地理', '歷史', '公民', '科技', '健體', '綜合']
        },
        Subject: {
            '國文': ['一年級', '二年級', '三年級'],
            '英語': ['一年級', '二年級', '三年級'],
            '數學': ['一年級', '二年級', '三年級'],
            '自然': ['一年級', '二年級', '三年級'],
            '地理': ['一年級', '二年級', '三年級'],
            '歷史': ['一年級', '二年級', '三年級'],
            '公民': ['一年級', '二年級', '三年級'],
            '科技': ['一年級', '二年級', '三年級'],
            '健體': ['一年級', '二年級', '三年級'],
            '綜合': ['一年級', '二年級', '三年級']
        }
    },
    '高中': {
        Grade: {
            '一年級': ['國文', '英文', '數學', '物理', '化學', '生物', '地科', '歷史', '地理', '公社'],
            '二年級': ['國文', '英文', '數學', '物理', '化學', '生物', '地科', '歷史', '地理', '公社'],
            '三年級': ['國文', '英文', '數學', '物理', '化學', '生物', '地科', '歷史', '地理', '公社']
        },
        Subject: {
            '國文': ['一年級', '二年級', '三年級'],
            '英文': ['一年級', '二年級', '三年級'],
            '數學': ['一年級', '二年級', '三年級'],
            '物理': ['一年級', '二年級', '三年級'],
            '化學': ['一年級', '二年級', '三年級'],
            '生物': ['一年級', '二年級', '三年級'],
            '地科': ['一年級', '二年級', '三年級'],
            '歷史': ['一年級', '二年級', '三年級'],
            '地理': ['一年級', '二年級', '三年級'],
            '公社': ['一年級', '二年級', '三年級']
        }
    }
}

export const gradeTable = {
    '一年級': {
        EducationalSystem: {
            '國小': ['國語', '數學', '生活', '健體'],
            '國中': ['國文', '英語', '數學', '自然', '地理', '歷史', '公民', '科技', '健體', '綜合'],
            '高中': ['國文', '英文', '數學', '物理', '化學', '生物', '地科', '歷史', '地理', '公社']
        },
        Subject: {
            '國語': ['國小'],
            '數學': ['國小', '國中', '高中'],
            '生活': ['國小'],
            '健體': ['國小', '國中'],
            '綜合': ['國小', '國中'],
            '國文': ['國中', '高中'],
            '英語': ['國中'],
            '英文': ['高中'],
            '自然': ['國中'],
            '地理': ['國中', '高中'],
            '歷史': ['國中', '高中'],
            '公民': ['國中'],
            '科技': ['國中'],
            '物理': ['高中'],
            '化學': ['高中'],
            '生物': ['高中'],
            '地科': ['高中'],
            '公社': ['高中']
        }
    },
    '二年級': {
        EducationalSystem: {
            '國小': ['國語', '數學', '生活', '健體'],
            '國中': ['國文', '英語', '數學', '自然', '地理', '歷史', '公民', '科技', '健體', '綜合'],
            '高中': ['國文', '英文', '數學', '物理', '化學', '生物', '地科', '歷史', '地理', '公社']
        },
        Subject: {
            '國語': ['國小'],
            '數學': ['國小', '國中', '高中'],
            '生活': ['國小'],
            '健體': ['國小', '國中'],
            '綜合': ['國小', '國中'],
            '國文': ['國中', '高中'],
            '英語': ['國中'],
            '英文': ['高中'],
            '自然': ['國中'],
            '地理': ['國中', '高中'],
            '歷史': ['國中', '高中'],
            '公民': ['國中'],
            '科技': ['國中'],
            '物理': ['高中'],
            '化學': ['高中'],
            '生物': ['高中'],
            '地科': ['高中'],
            '公社': ['高中']
        }
    },
    '三年級': {
        EducationalSystem: {
            '國小': ['國語', '數學', '自然', '社會', '健體', '藝文', '綜合'],
            '國中': ['國文', '英語', '數學', '自然', '地理', '歷史', '公民', '科技', '健體', '綜合'],
            '高中': ['國文', '英文', '數學', '物理', '化學', '生物', '地科', '歷史', '地理', '公社']
        },
        Subject: {
            '國語': ['國小'],
            '數學': ['國小', '國中', '高中'],
            '自然': ['國中', '國中'],
            '社會': ['國小', '國中'],
            '健體': ['國小', '國中'],
            '藝文': ['國小'],
            '綜合': ['國小', '國中'],
            '國文': ['國中', '高中'],
            '英語': ['國中'],
            '地理': ['國中', '高中'],
            '歷史': ['國中', '高中'],
            '公民': ['國中'],
            '科技': ['國中'],
            '英文': ['高中'],
            '物理': ['高中'],
            '化學': ['高中'],
            '生物': ['高中'],
            '地科': ['高中'],
            '公社': ['高中']
        }
    },
    '四年級': {
        EducationalSystem: {
            '國小': ['國語', '數學', '自然', '社會', '健體', '藝文', '綜合']
        },
        Subject: {
            '國語': ['國小'],
            '數學': ['國小'],
            '自然': ['國小'],
            '社會': ['國小'],
            '健體': ['國小'],
            '藝文': ['國小'],
            '綜合': ['國小']
        }
    },
    '五年級': {
        EducationalSystem: {
            '國小': ['國語', '數學', '自然', '社會', '健體', '藝文', '綜合']
        },
        Subject: {
            '國語': ['國小'],
            '數學': ['國小'],
            '自然': ['國小'],
            '社會': ['國小'],
            '健體': ['國小'],
            '藝文': ['國小'],
            '綜合': ['國小']
        }
    },
    '六年級': {
        EducationalSystem: {
            '國小': ['國語', '數學', '自然', '社會', '健體', '藝文', '綜合']
        },
        Subject: {
            '國語': ['國小'],
            '數學': ['國小'],
            '自然': ['國小'],
            '社會': ['國小'],
            '健體': ['國小'],
            '藝文': ['國小'],
            '綜合': ['國小']
        }
    }
}

export const subjectTable = {
    '國語': {
        Grade: {
            '一年級': ['國小'],
            '二年級': ['國小'],
            '三年級': ['國小'],
            '四年級': ['國小'],
            '五年級': ['國小'],
            '六年級': ['國小']
        },
        EducationalSystem: {
            '國小': ['一年級', '二年級', '三年級', '四年級', '五年級', '六年級']
        }
    },
    '數學': {
        Grade: {
            '一年級': ['國小', '國中', '高中'],
            '二年級': ['國小', '國中', '高中'],
            '三年級': ['國小', '國中', '高中'],
            '四年級': ['國小'],
            '五年級': ['國小'],
            '六年級': ['國小']
        },
        EducationalSystem: {
            '國小': ['一年級', '二年級', '三年級', '四年級', '五年級', '六年級'],
            '國中': ['一年級', '二年級', '三年級'],
            '高中': ['一年級', '二年級', '三年級']
        }
    },
    '生活': {
        Grade: {
            '一年級': ['國小'],
            '二年級': ['國小']
        },
        EducationalSystem: {
            '國小': ['一年級', '二年級']
        }
    },
    '自然': {
        Grade: {
            '一年級': ['國中'],
            '二年級': ['國中'],
            '三年級': ['國小', '國中'],
            '四年級': ['國小'],
            '五年級': ['國小'],
            '六年級': ['國小']
        },
        EducationalSystem: {
            '國小': ['三年級', '四年級', '五年級', '六年級'],
            '國中': ['一年級', '二年級', '三年級']
        }
    },
    '社會': {
        Grade: {
            '三年級': ['國小'],
            '四年級': ['國小'],
            '五年級': ['國小'],
            '六年級': ['國小']
        },
        EducationalSystem: {
            '國小': ['三年級', '四年級', '五年級', '六年級']
        }
    },
    '健體': {
        Grade: {
            '一年級': ['國小', '國中'],
            '二年級': ['國小', '國中'],
            '三年級': ['國小', '國中'],
            '四年級': ['國小'],
            '五年級': ['國小'],
            '六年級': ['國小']
        },
        EducationalSystem: {
            '國小': ['一年級', '二年級', '三年級', '四年級', '五年級', '六年級'],
            '國中': ['一年級', '二年級', '三年級']
        }
    },
    '藝文': {
        Grade: {
            '三年級': ['國小'],
            '四年級': ['國小'],
            '五年級': ['國小'],
            '六年級': ['國小']
        },
        EducationalSystem: {
            '國小': ['三年級', '四年級', '五年級', '六年級']
        }
    },
    '綜合': {
        Grade: {
            '一年級': ['國中'],
            '二年級': ['國中'],
            '三年級': ['國小', '國中'],
            '四年級': ['國小'],
            '五年級': ['國小'],
            '六年級': ['國小']
        },
        EducationalSystem: {
            '國小': ['三年級', '四年級', '五年級', '六年級'],
            '國中': ['一年級', '二年級', '三年級']
        }
    },
    '國文': {
        Grade: {
            '一年級': ['國中', '高中'],
            '二年級': ['國中', '高中'],
            '三年級': ['國中', '高中']
        },
        EducationalSystem: {
            '國中': ['一年級', '二年級', '三年級'],
            '高中': ['一年級', '二年級', '三年級']
        }
    },
    '英語': {
        Grade: {
            '一年級': ['國中'],
            '二年級': ['國中'],
            '三年級': ['國中']
        },
        EducationalSystem: {
            '國中': ['一年級', '二年級', '三年級']
        }
    },
    '地理': {
        Grade: {
            '一年級': ['國中', '高中'],
            '二年級': ['國中', '高中'],
            '三年級': ['國中', '高中']
        },
        EducationalSystem: {
            '國中': ['一年級', '二年級', '三年級'],
            '高中': ['一年級', '二年級', '三年級']
        }
    },
    '歷史': {
        Grade: {
            '一年級': ['國中', '高中'],
            '二年級': ['國中', '高中'],
            '三年級': ['國中', '高中']
        },
        EducationalSystem: {
            '國中': ['一年級', '二年級', '三年級'],
            '高中': ['一年級', '二年級', '三年級']
        }
    },
    '公民': {
        Grade: {
            '一年級': ['國中'],
            '二年級': ['國中'],
            '三年級': ['國中']
        },
        EducationalSystem: {
            '國中': ['一年級', '二年級', '三年級']
        }
    },
    '科技': {
        Grade: {
            '一年級': ['國中'],
            '二年級': ['國中'],
            '三年級': ['國中']
        },
        EducationalSystem: {
            '國中': ['一年級', '二年級', '三年級']
        }
    },
    '英文': {
        Grade: {
            '一年級': ['高中'],
            '二年級': ['高中'],
            '三年級': ['高中']
        },
        EducationalSystem: {
            '高中': ['一年級', '二年級', '三年級']
        }
    },
    '物理': {
        Grade: {
            '一年級': ['高中'],
            '二年級': ['高中'],
            '三年級': ['高中']
        },
        EducationalSystem: {
            '高中': ['一年級', '二年級', '三年級']
        }
    },
    '化學': {
        Grade: {
            '一年級': ['高中'],
            '二年級': ['高中'],
            '三年級': ['高中']
        },
        EducationalSystem: {
            '高中': ['一年級', '二年級', '三年級']
        }
    },
    '生物': {
        Grade: {
            '一年級': ['高中'],
            '二年級': ['高中'],
            '三年級': ['高中']
        },
        EducationalSystem: {
            '高中': ['一年級', '二年級', '三年級']
        }
    },
    '地科': {
        Grade: {
            '一年級': ['高中'],
            '二年級': ['高中'],
            '三年級': ['高中']
        },
        EducationalSystem: {
            '高中': ['一年級', '二年級', '三年級']
        }
    },
    '公社': {
        Grade: {
            '一年級': ['高中'],
            '二年級': ['高中'],
            '三年級': ['高中']
        },
        EducationalSystem: {
            '高中': ['一年級', '二年級', '三年級']
        }
    }
}