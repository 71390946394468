
import React, { useEffect, useState } from 'react';
import { useStore, StoreTypes } from 'context';
import styles from './index.module.scss';
import { Roles } from 'constants/role';
import ChatRoom from 'components/ChatRoom';
import { useOrderStudent } from 'customHooks/orderStudent';
import LiveStreamMasterVideo from 'components/LiveStreamMasterVideo';
import LiveStreamLocalVideo from 'components/LiveStreamLocalVideo';
import LiveStreamRemoteVideoGroup from 'components/LiveStreamRemoteVideoGroup';
import LiveStreamEmojiCounts from 'components/LiveStreamEmojiCounts';
import LiveStreamRemoteVideo from 'components/LiveStreamRemoteVideo';
import classnames from 'classnames';
import Icon from 'components/Icon';

import {
    useMeetingManager,
    useAudioVideo
} from 'amazon-chime-sdk-component-library-react';


const LiveStreamViewerPanel = () => {
    const [{ isMultiple, isFullVideo, isMute }] = useStore(StoreTypes.course);

    const meetingManager = useMeetingManager();
    const selfAttendeeId = meetingManager.configuration.credentials.attendeeId;

    const audioVideo = useAudioVideo();
    const [isStreaming, setStreaming] = useState(!isMultiple);

    const { subscribeOrderStudent } = useOrderStudent();

    useEffect(() => {
        if (!audioVideo || !selfAttendeeId) return;
        subscribeOrderStudent(data => {
            const { attendeeId, isActive } = data;
            if (attendeeId === selfAttendeeId) {
                setStreaming(isActive)
            }
        })
    }, [audioVideo, selfAttendeeId, subscribeOrderStudent])

    useEffect(() => {
        (async()=>{
            if(isMute){
                await meetingManager.audioVideo.realtimeUnmuteLocalAudio();
            }else{
                await meetingManager.audioVideo.realtimeMuteLocalAudio();
            }
        })()
        
    },[isMute, meetingManager])

    return (
        <div className={classnames(styles.discussionPanel, styles.wrapper)}>
            <div id="content" className={styles.chatContent}>
                <div className={styles.teacherVideo}>
                    {
                        !isFullVideo && <LiveStreamMasterVideo isFull={isFullVideo} />
                    }
                    <div className={classnames(styles.fullVideoMessage, { [styles.fullVideoMessageHide]: !isFullVideo })} >
                        <Icon name="StudentVideoIsFull" />
                    </div>
                </div>
                <div className={styles.localVideo}>
                    <LiveStreamLocalVideo streaming={isStreaming || !isMultiple} />
                </div>
                {
                    isMultiple ? (
                        <div className={styles.remoteVideoContainer}>
                            <LiveStreamRemoteVideoGroup />
                        </div>
                    ) : (
                            <LiveStreamEmojiCounts />
                        )
                }
                <div className={styles.chatRoomWrapper}>
                    <ChatRoom />
                </div>
            </div>
        </div>
    )
}



export default LiveStreamViewerPanel;
