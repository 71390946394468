import React from 'react';
import Radio from 'components/common/Radio';
import styles from './index.module.scss';

const fillTypeData = [
  { value: 'hollow', label: '空心' },
  { value: 'solid', label: '實心' }
];

const FillOptionSelector = ({ fillType, onFillTypeChange }) => {
  return (
    <div className={styles.radio}>
        {fillTypeData.map(item => (
          <Radio
            className={styles.radio}
            name="fillOptionSelector"
            value={item.value.toString()}
            currentValue={fillType}
            onChange={e => onFillTypeChange(e.target.value)}
            label={<div className={styles.label}>{item.label}</div>}
          />
        ))}
      </div>
  )
};


export default FillOptionSelector;