import React,{ useRef, useCallback } from 'react';
import { useBoolean } from 'customHooks/boolean';
import { useOutsideAlerter } from 'customHooks/outsideAlerter';
import Icon from 'components/Icon';
import classnames from 'classnames';
import styles from './index.module.scss';


const dataType = [{
    label:'label',
    value:'value'
}];


const DropdownItem = ({ active = false ,children, onClick}) => {
    return (
        <div 
            className={classnames(styles.menuItem,{ [styles.active]: active})} 
            onClick={onClick}
        >
            {children}
        </div>
    )
}

const Dropdown = ({ className,onChange=()=>{}, data = dataType, value = data[0].value ,children}) => {
    const ref = useRef();
    const [isShowMenu,{ toggle:toggleMenuShow, setFalse:setMenuClose }] = useBoolean();
    const currentIndex = data.findIndex(item=> item.value === value);
    
    const menuItemClickHandler = useCallback(index=>{
        setMenuClose();
        onChange(data[index].value);
    },[data, onChange, setMenuClose])
    
    useOutsideAlerter(ref, setMenuClose);
    return (
        <div ref={ref} className={classnames(styles.dropdown,className)}>
            <div className={styles.value} onClick={toggleMenuShow}>{
                children.filter((item,index)=> index===currentIndex).map(item=>(
                    <DropdownItem>
                    {item}
                    </DropdownItem>
                ))
            }</div>
            <div className={styles.button} onClick={toggleMenuShow}>
                <Icon type="svg" name="ChevronDown" />
            </div>
            {
                isShowMenu && (
                    <div className={styles.menu}>
                        {children.map((Item,index)=> {
                            return (
                                <DropdownItem active={index === currentIndex} onClick={()=> menuItemClickHandler(index)}>
                                    {Item}
                                </DropdownItem>
                            )
                        })}
                    </div>
                )
            }
       </div>
    );
}

export default Dropdown;