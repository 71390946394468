import React from 'react';
import Icon from 'components/Icon';
import classnames from 'classnames';
import styles from './index.module.scss';

const AlertModal = ({ 
  title,
  message,
  onClose,
  onConfirm,
  confirmLabel = '確定',
  cancelLabel = '取消',
 }) => {

  return (
    <div className={styles.alertModal}>
      <div className={styles.closeIcon} onClick={onClose}>
        <Icon type="svg" name="Close" />
      </div>
      <div className={styles.content}>
        <div className={styles.title}>{title}</div>
        <div className={styles.message}>{message}</div>
      </div>
      <div className={styles.buttonGroup}>
        <div className={classnames(styles.button, styles.cancel)} onClick={onClose}>{cancelLabel}</div>
        { onConfirm && (
          <div className={classnames(styles.button, styles.confirm)} onClick={onConfirm}>{confirmLabel}</div>
        )}
      </div>
    </div>
  )
};


export default AlertModal;