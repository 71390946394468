import React, { useRef, useEffect, useCallback } from 'react';
import ReactDOM from 'react-dom';
import styles from './index.module.scss';
import Button from '@material-ui/core/Button';
import { useStore, StoreTypes } from 'context';
import * as types from 'constants/actionTypes';
import { useInteractiveObjectContentCommandFactory } from 'customHooks/InteractiveObjectContentCommands/commandFactory';
import { ModalComponentGenerator, ModalComponentType } from 'components/common/Modal/ModalComponentGenerator';
import useModal from 'components/common/Modal/useModal';
import Repository from 'repositories/Repository';
import { useCourse } from 'customHooks/course'
import {
    InteractiveObjectEvent
} from 'events/EventTypes';

const { BookContentRepository } = Repository;

const Submenu = ({ submenuInfo }) => {
    const [{ role }] = useStore(StoreTypes.user);
    const [, readerDispatch] = useStore(StoreTypes.reader);
    const { list, position, target, showEdit, canvasSVGObject } = submenuInfo;
    const newList = showEdit ? canvasSVGObject.info.list : list;
    const submenuContent = useRef(null);
    const CommandFactory = useInteractiveObjectContentCommandFactory();
    const [, { openModal, setModalComponents }] = useModal();
    const [, { broadcastEvent }] = useCourse();

    const itemClickHandler = useCallback((e, item) => {
        const command = CommandFactory.createCommand(item);
        command && command.execute({ json: item });

        broadcastEvent({
            eventType: InteractiveObjectEvent.SetInteractiveObjectStateEvent,
            payload: {
                json: item,
                remote: true
            }
        })
    }, [broadcastEvent, CommandFactory])

    const editClickHandler = () => {
        const component = ModalComponentGenerator.generate(ModalComponentType.Link, canvasSVGObject);
        setModalComponents(component);
        openModal();
    }

    const closeSubmenu = () => {
        readerDispatch({ type: types.SET_SUBMENU_INFO, submenuInfo: null })
    }

    useEffect(() => {
        const submenuContentDom = ReactDOM.findDOMNode(submenuContent.current)
        const editBtn = submenuContentDom.querySelectorAll('div');
        if (!target.getBoundingClientRect) return;
        const { x, y, width, height } = target.getBoundingClientRect()

        if (submenuContentDom) {
            switch (position) {
                case "top":
                    submenuContentDom.style.top = (y - (submenuContent.current.clientHeight) - 5) + "px";
                    submenuContentDom.style.left = (x) + "px";
                    break;
                case "right":
                    if (editBtn.length > 0) {
                        editBtn[0].style.margin = "0 auto 0 0";
                    }
                    submenuContentDom.style.top = (y) + "px";
                    submenuContentDom.style.left = (x + width + 10) + "px";
                    break;
                case "bottom":
                    submenuContentDom.style.top = (y + height + 5) + "px";
                    submenuContentDom.style.left = (x) + "px";
                    break;
                case "left":
                    submenuContentDom.style.top = (y) + "px";
                    submenuContentDom.style.left = (x - submenuContent.current.clientWidth - 10) + "px";
                    break;
                case "topRight":
                    if (editBtn.length > 0) {
                        editBtn[0].style.margin = "0 auto 0 0";
                    }
                    submenuContentDom.style.top = (y - (submenuContent.current.clientHeight) - 5) + "px";
                    submenuContentDom.style.left = (x + width + 10) + "px";
                    break;
                case "topLeft":
                    submenuContentDom.style.top = (y - (submenuContent.current.clientHeight) - 5) + "px";
                    submenuContentDom.style.left = (x - submenuContent.current.clientWidth - 10) + "px";
                    break;
                default:
                    submenuContentDom.style.top = (y + height + 5) + "px";
                    submenuContentDom.style.left = (x) + "px";
                    break;
            }
        }
    })

    return <div className={styles.submenuContent} onClick={closeSubmenu}>
        <div className={styles.items} ref={submenuContent}>
            {
                showEdit &&

                <div className={styles.edit}
                    onClick={() => { editClickHandler() }}
                >編輯</div>
            }
            <ul className={styles.itemul}>
                {
                    newList &&
                    newList.map((item, index) => {
                        return BookContentRepository.getIsShowInteractiveObject({ role, interactiveObject: item })
                            ? <li className={styles.itemli} key={index}>
                                {
                                    <Button
                                        className="submenuLists"
                                        style={{
                                            textTransform: "none",
                                            color: "#fff",
                                            minWidth: "max-content",
                                            width: "100%",
                                            display: "flex",
                                            justifyContent: "flex-start",
                                            height: "30px"
                                        }} onClick={(e) => { itemClickHandler(e, item) }}>{item.name}</Button>
                                }
                            </li> : null
                    })
                }
            </ul>
        </div>
    </div>
};

export default Submenu;