import React, { useCallback, useState, useEffect, useMemo } from 'react';
import * as types from 'constants/actionTypes';
import styles from './index.module.scss';
import { EventBus } from 'events/EventBus';
import { ReaderEvent, ReaderToolsEvent } from 'events/EventTypes';
import { ReaderZoomType, } from 'constants/ReaderTools';
import { useStore, StoreTypes } from 'context';
import classnames from 'classnames';
import Icon from 'components/Icon';

const AreaZoomController = ({ areaZoomInteractiveObjects }) => {
    const reducers = useStore();
    const [{ readerToolHeight }, readerDispatch] = reducers[StoreTypes.reader];
    const height = useMemo(()=> window.innerHeight - readerToolHeight,[readerToolHeight]);
    
    const { nowStep, areaZoomInfos } = areaZoomInteractiveObjects
    const [step, setStep] = useState(areaZoomInfos.findIndex((obj, index) => obj.step === nowStep))
    const [prevBtnShow, setPrevBtn] = useState(false)
    const [nextBtnShow, setNextBtn] = useState(false)

    const showHideBtn = useCallback((step) => {
        let index = step;
        setPrevBtn(false)
        setNextBtn(false)
        if (areaZoomInfos.length > 1) {
            if (index <= 0) {
                index = 0
                setNextBtn(true)
            } else if (index >= areaZoomInfos.length - 1) {
                index = (areaZoomInfos.length - 1)
                setPrevBtn(true)
            } else {
                setPrevBtn(true)
                setNextBtn(true)
            }
            setStep(index)
            areaZoomInfos && EventBus.emit({ event: ReaderEvent.AreaZoomForPageButtonEvent, payload: { rect: areaZoomInfos[index] } })
        }
    }, [areaZoomInfos])

    useEffect(() => {
        showHideBtn(step)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const prevBtn = useCallback(() => {
        showHideBtn(step - 1)
    }, [showHideBtn, step])

    const nextBtn = useCallback(() => {
        showHideBtn(step + 1)
    }, [showHideBtn, step])

    const exitBtn = useCallback(() => {
        EventBus.emit({ event: ReaderToolsEvent.SetReaderZoomEvent, payload: { type: ReaderZoomType.OriginZoom } })
        readerDispatch({ type: types.SET_AREA_ZOOM_INTERACTIVE_OBJECTS, areaZoomInfos: null })
    }, [readerDispatch])

    return (
        <div className={styles.areaZoomController} style={{height : height + 'px' }}>
            <div className={styles.leftExit}>
                <div className={styles.btn} onClick={() => exitBtn()}><Icon name="closeMathAreaZoomButton" /></div>
            </div>
            <div className={styles.prevNextContent}>
                <div className={classnames(styles.btn, styles.prev, styles[prevBtnShow && 'show'])} onClick={() => prevBtn()}><Icon name="prevMathAreaZoomButton" /></div>
                <div className={classnames(styles.btn, styles.next, styles[nextBtnShow && 'show'])} onClick={() => nextBtn()}><Icon name="nextMathAreaZoomButton" /></div>
            </div>
            <div className={classnames(styles.btn, styles.rightExit)}>
                <div className={styles.btn} onClick={() => exitBtn()}><Icon name="closeMathAreaZoomButton" /></div>
            </div>
        </div>
    );
};

export default AreaZoomController;
